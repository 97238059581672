import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import ObrasOfertaProveedorForm from "./ObrasOfertaProveedorForm";
import { useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import { MainTitle } from "../../common/StyledComponents";
import {Col, Container, Row} from "react-bootstrap";

const ObrasOfertaProveedor = () => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [obra, setObra] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { id } = params;

    setObra(id);

    const getData = async (id) => {
      setIsLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/obras/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        const data = await res.json();
        delete data.users;
        setData(data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error(`${t("error")}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      getData(id);
    }
  }, []);

  const handleSave = async (newData) => {
    for (const categoria of newData.categoriasValores) {
      const obj = {
        id: newData.id,
        proveedor: newData.proveedor.value,
        categoria: categoria.value,
        obra,
      };
      try {
        const res = await fetch(
          // `${process.env.REACT_APP_API_URL}/obras/proveedor`,
          `${process.env.REACT_APP_API_URL}/participantes`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(obj),
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        const data = await res.json();
        setTimeout(() => {
          history.location.pathname.includes("obras")
            ? history.push(`/obras/detalles/${newData.id}`)
            : history.push(`/ejecucion/detalles/${newData.id}`);
        }, 500);
      } catch (err) {
        toast.error(`${t("error")}`);
      }
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  if (!isLoading) {
    return (
        <Container>
          <Row>
            <Col>
              <div className={"card p-3"}>
                <div className={"card-title"}>
                  <div className={"d-flex justify-content-between"}>
                    <MainTitle>Añadir empresa participante</MainTitle>
                  </div>
                </div>
                <div className={"card-body pt-0"}>
                  <ObrasOfertaProveedorForm item={data} doSave={handleSave} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    );
  }
};

export default ObrasOfertaProveedor;
