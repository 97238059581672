import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";

import { Button, Input } from "../index";
import ValoracionScore from "../../common/ValoracionScore";
import useAppContext from "../../data/store";
import * as _ from "lodash";

const FullProfesionalDetail = ({ name, value, type = null, data = null }) => {

  const { t } = useTranslation();
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState();
  const [item, setItem] = useState(value);
  const [typeElement, setTypeElement] = useState(type);
  const { register, handleSubmit, setValue } = useForm();
  const [recipients, setRecipients] = useState();
  const [{ user }] = useAppContext();
  const [isLogin, setIsLogin] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    handleButtons(type);
  }, []);

  useEffect(() => {
    const emails = JSON.parse(localStorage.getItem('emails'));
    setRecipients(emails);
  }, []);

  const handleEdit = () => {
    handleButtons("edit-email");
  };

  const handleClose = () => {
    setResult("");
    handleButtons("email");
  };

  useEffect(() => {
    const role =
      !_.isNull(localStorage.getItem("jdo-userData")) &&
      JSON.parse(localStorage.getItem("jdo-userData")).role.name;
    const isAdmin = (role && role === "Administrador") || user.isAdmin;
    setIsLogin(role);
    setRole(isAdmin);
    let isMounted = true;
    return () => {
      isMounted = false;
    }
  }, []);

  const getData = async ({ email }) => {
    try {
      setLoading(true);
      let datos = data;
      datos[name] = email;
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/proveedors/${data.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
          },
          body: JSON.stringify(datos),
        }
      );
      setLoading(false);
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const resp = await res.json();
      setItem(resp[name]);
      handleClose();
      return resp;
    } catch (error) {
      setResult("Error al guadar. Verifique e intentelo de nuevo");
      console.error("err :>> ", error);
    } finally {
    }
  };

  const onSubmit = async ({ email }) => {
    const resp = await getData({ email });
  };

  const handleButtons = (t) => {
    if (t === null || (!item && (t !== "email" || t !== "edit-email")))
      setItem(item ? item : "-");
    setTypeElement(t);
  };

  const handleChangeRecipients = async (email) => {
    let emails = JSON.parse(localStorage.getItem('emails'));
    if (!emails.includes(email)) {
      emails.push(email);
    } else {
      emails = emails.filter((e) => e !== email ? e : null);
    }
    await localStorage.setItem('emails', JSON.stringify(emails));
    setRecipients(emails);
  }

  switch (typeElement) {
    case "link":
      if (!isLogin) {
        return (
          <tr>
            <th scope="row" className={"align-middle"}>
              <strong>{t(name)}</strong>
            </th>
            <td className={"align-middle"}>
              regístrate para ver el link
            </td>
          </tr>
        );
      }
      return (
        <tr>
          <th scope="row" className={"align-middle"}>
            <strong>{t(name)}</strong>
          </th>
          <td className={"align-middle"}>
            <a href={item} target="_blank" rel="noreferrer">
              {item}
            </a>
          </td>
        </tr>
      );
    case "tel":
      if (!isLogin) {
        return (
          <tr>
            <th scope="row" className={"align-middle"}>
              <strong>{t(name)}</strong>
            </th>
            <td className={"align-middle"}>
              regístrate para ver el teléfono
            </td>
          </tr>
        );
      }
      return (
        <tr>
          <th scope="row" className={"align-middle"}>
            <strong>{t(name)}</strong>
          </th>
          <td className={"align-middle"}>
            {item !== "-" ? <a href={"tel:" + item}>{item}</a> : "-"}
          </td>
        </tr>
      );
    case "score":
      return (
        <tr>
          <th scope="row" className={"align-middle"}>
            <strong>{t(name)}</strong>
          </th>
          <td className={"align-middle"}>
            <ValoracionScore score={value} small={true} />
          </td>
        </tr>
      );
    case "email":
      if (!isLogin) {
        return (
          <tr>
            <th scope="row" className={"align-middle"}>
              <strong>{t(name)}</strong>
            </th>
            <td className={"align-middle"}>
              regístrate para ver el email
            </td>
          </tr>
        );
      }
      return (
        <tr>
          <th scope="row" className={"align-middle"}>
            <strong>{t(name)}</strong>
          </th>
          <td className={"align-middle"}>
            <div className={"d-flex flex-row align-items-center justify-content-between"}>
              {item !== "-" ? <a href={"mailto:" + item}>{item}</a> : "-"}
              {item && (
                <Form.Check
                  type={'checkbox'}
                  id={`default-checkbox`}
                  label={``}
                  className="width-1-2 height-1-2"
                  checked={localStorage.getItem('emails')?.includes(item)}
                  onClick={() => handleChangeRecipients(item)}
                  onChange={() => {}}
                />
              )}
              {data && (
                <Button
                  text={""}
                  className={"btn-outline-success btn-sm mx-2"}
                  icon={{ prefix: "far", iconName: "edit" }}
                  onClick={() => {
                    handleEdit();
                  }}
                />
              )}
            </div>
          </td>
        </tr>
      );
    case "edit-email":
      setValue("email", item === "-" ? null : item);
      return (
        <tr>
          <th scope="row" className={"align-middle"}>
            <strong>{t(name)}</strong>
          </th>
          <td className={"align-middle"}>
            <div
              className={"d-flex flex-row align-items-center justify-content-between"}>
              <form onSubmit={handleSubmit(onSubmit)} className={"w-100"}>
                <Input
                  className="form-control"
                  placeholder="Correo Electrónico"
                  register={register}
                  name={"email"}
                  error={result}
                />
                <div className={"d-flex justify-content-end mt-1"}>
                  <Button
                    text={""}
                    className={"btn-outline-info btn-sm mx-1"}
                    icon={{ prefix: "far", iconName: "edit" }}
                    type={"submit"}
                    loading={loading}
                  />
                  <Button
                    text={""}
                    className={"btn-outline-danger btn-sm"}
                    icon={{ prefix: "fas", iconName: "times" }}
                    loading={loading}
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
              </form>
            </div>
          </td>
        </tr>
      );
    default:
      return (
        <tr>
          <th scope="row" className={"align-middle"}>
            <strong>{t(name)}</strong>
          </th>
          <td className={"align-middle"}>
            <span>
              {item === 1
                ? "Distribuidor"
                : item === 2
                ? "Fabricante"
                : item === 3
                ? "Subcontratista"
                : item
                ? item
                : "-"}
            </span>
            {/* <span>{item ? item : "-"}</span> */}
          </td>
        </tr>
      );
  }
};

export default FullProfesionalDetail;
