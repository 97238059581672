import React, { useState, useEffect } from "react";

import { Box, Flex } from "reflexbox";
import { Button, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { X } from "react-bootstrap-icons";
import * as _ from "lodash";
import * as moment from "moment";
import qs from "qs";

import { getProviderByID, searchByName } from "../../services/provider.service";
import { getCategoryById } from "../../services/categories.service";
import { getEInformaToken } from "../../logic/einforma";
import { getListCommunities } from "../../services/community.service";
import { getOfferByID } from "../../services/offers.service";
import { ModalOfEInforma } from "../../common/Modals/ModalOfEInforma";
import { updateChapter, getChapterByID } from "../../services/chapter.service";
import ChangeScopeModal from "../../common/Modals/ChangeScopeModal";
import ObrasProveedoresListItem from "./ObrasProveedoresListItem";
import ObrasProveedoresSeleccionItem from "./ObrasProveedoresSelecciontItem";
import TableContent from "../tablas/tables";
import useAppContext from "../../data/store";
import { searchBlockedProviders } from "../../services/blocked.providers.service";
import { getUserID } from "../../utils";


const ObrasProveedoresList = ({
  isLogged,
  final,
  isOferta,
  allData,
  allDataToUsersDuty,
  category,
}) => {
  const { t } = useTranslation();
  const [{ app }, dispatch] = useAppContext();

  const [
    {
      app: { obra, capitulo, oferta },
    },
  ] = useAppContext();

  const { estado_obra } = obra;

  const [modal, setModal] = React.useState(false);
  const [datos, setDatos] = useState([]);
  const [isGettingTodos, setIsGettingTodos] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [proveedores, setProveedores] = useState([]);
  const [ofertas, setOfertas] = useState(null);
  const [ofertasDefinitivas, setOfertasDefinitivas] = useState(null);
  const [proveedor, setProveedor] = useState(null);
  const [filtroRecuperado, setFiltroRecuperado] = useState(null);
  const [filtros, setFiltros] = useState(false);
  const [provincias, setProvincias] = useState([]);
  const [tipoProveedores, setTipoProveedores] = useState([]);
  const [listProviders, setListProviders] = useState([]);
  const [filtered, setFiltered] = useState();
  const [messageModel, setMessageModel] = useState();
  const [communities, setCommunities] = useState();
  const [showChangeScopeModal, setShowChangeScopeModal] = useState(false);
  const [typeScopeModal, setTypeScopeModal] = useState("");
  const [providerToShow, setProviderToShow] = useState(null);
  const [emailsToSend, setEmailsToSend] = useState([]);
  const [changeFilter, setChangeFilter] = useState("");
  const [toEdit, setToEdit] = useState(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const [datoseInforma, setDatosInforma] = useState([]);
  const [fullscreenModal, setFullscreenModal] = useState(true);
  const [valueOfInput, setValueOfInput] = useState("");
  const [listBlockedProviders, setListBlockedProviders] = useState([]);

  const handleCloseModalInforma = () => {
    setShowNewModal((prevState) => !prevState);
    getFilteredProveedores();
  };

  const handleCloseChangeScopeModal = () => {
    setShowChangeScopeModal(false);
    getFilteredProveedores();
  };

  useEffect(() => {
    handleGetListCommunities();
  }, []);

  const handleGetListCommunities = async () => {
    const response = await getListCommunities();
    setCommunities(response);
  };

  useEffect(() => {
    let emails = [];
    localStorage.setItem("emails", JSON.stringify(emails));
    ofertas?.forEach((o) => {
      if (!o?.proveedor?.email?.includes("-")) {
        emails = [...emails, o?.proveedor?.email];
      }
      if (!o?.proveedor?.email_contacto?.includes("-")) {
        emails = [...emails, o?.proveedor?.email_contacto];
      }
      localStorage.setItem("emails", JSON.stringify(emails));
    });
  }, [ofertas]);

  useEffect(() => {

    let objects = app?.obra?.capitulos?.filter((element) => element?.id === capitulo?.id)[0]?.offers;
    const user = getUserID();

    objects = objects?.map((element) => {
      const allowForm = new Date("2025-03-10").getTime();
      const comments = element?.comentarios?.filter((item) => {
        const commendDate = new Date(item?.fecha).getTime();
        if (commendDate > allowForm && item?.create_by?.id !== user && !item?.reads?.map((item) => item.id).includes(user)) {
          return item;
        }
        return null;
      }).filter((item) => item !== null);
      return {
        ...element,
        commentsCounter: comments?.length,
      }
    });

    setOfertas(objects);

  }, [app]);

  const getAllProvincias = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/provincias?_limit=-1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const provinciasProveedores = await res.json();
      const cadaProvincia = provinciasProveedores
        .map((proveedor) => ({
          label: proveedor.nombre,
          value: proveedor.nombre,
        }))
        .filter((f) => f.value != null && f.value !== "");

      const provinciasOrden = _.sortBy(cadaProvincia, (o) => {
        const label = _.deburr(o.label);
        return label;
      });
      setProvincias(provinciasOrden);
    } catch (err) {
      toast.error(`${t("error")}`);
    }

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/tipo-proveedors?_limit=-1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const datosTipos = await res.json();
      setTipoProveedores(datosTipos);
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  };

  const getOfertas = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/ofertas?capitulo=${capitulo.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      let data = await res.json();
      data = data.filter((f) => f.proveedor);

      const user = getUserID();

      data = data.map((element) => {
        const allowForm = new Date("2025-03-10").getTime();
        const comments = element?.comentarios?.filter((item) => {
          const commendDate = new Date(item?.fecha).getTime();
          if (commendDate > allowForm && item?.create_by?.id !== user && !item?.reads?.map((item) => item.id).includes(user)) {
            return item;
          }
          return null;
        }).filter((item) => item !== null);
        return {
          ...element,
          commentsCounter: comments?.length,
        }
      });

      const work = app?.obra;
      const chapter = capitulo.id;
      const object = work.capitulos.filter((element) => element.id === chapter)[0];
      const indexChapter = work.capitulos.indexOf(object);
      work.capitulos[indexChapter].offers = data;

      dispatch({
        origin: "app",
        type: "SET_OBRA",
        obra: work,
      });

      setOfertas(data);
    } catch (err) {
      console.error(`${t("error")}`);
    }
    // definitivas
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/ofertas?capitulo=${capitulo.id}&realizacion_null=false`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      let data = await res.json();
      data = data.filter((f) => f.proveedor);
      setOfertasDefinitivas(data);
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  };

  const anyStringToPascalCaseString = (str) => {
    str = str.replace(/[^\w\sÀ-ÿ]|_/g, "").replace(/\s+/g, " ");
    var words = str.split(/\s+/);
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    const newStr = words.join(" ");
    return newStr;
  };

  const removeAnyAccents = (str) => {
    let newStr = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return newStr;
  };

  const getFilters = async () => {
    let initialProvincias;
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/proveedors?categorias_contains=${
          capitulo.categoria.id
        }&comunidad_contains=${obra.comunidad.toUpperCase()}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const paraProvincias = await res.json();

      let provinciasList = paraProvincias.map((c) => c.provincia.toUpperCase());
      initialProvincias = _.uniq(provinciasList);

      // get saved filters from BE
      let savedFilters;
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/filtros?capitulo=${capitulo.id}&obra=${obra.id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        const filtrosRecuperados = await res.json();

        if (filtrosRecuperados.length === 0) {
          savedFilters = {
            provincia: [...initialProvincias],
            ambito: "NACIONAL",
            tipo_proveedor: [],
            score: false,
            web: false,
            nacional: true,
          };
          saveFiltro(null, savedFilters);
          setFiltros(savedFilters);
        } else {
          const son = filtrosRecuperados[0];
          savedFilters = {
            provincia: JSON.parse(son.provincia),
            ambito: son.ambito,
            tipo_proveedor: son.tipo_proveedores
              ? JSON.parse(son.tipo_proveedores)
              : [],
            score: son.score,
            web: son.web,
            nacional: son.nacional,
          };
          saveFiltro(filtrosRecuperados[0].id, savedFilters);
          setFiltros(savedFilters);
          setFiltroRecuperado(filtrosRecuperados[0].id);
        }
      } catch (error) {
        console.error("error :>> ", error);
      }

      await getFilteredProveedores(savedFilters)
    } catch (error) {
      console.error("error :>> ", error);
    }
  };

  const getFilteredProveedores = async (filters) => {
    try {
      // build filter query
      let query = {
        _where: {
          _or: [
            [{ provincia: filtros.provincia }],
            [
              { ambito: "REGIONAL" },
              { comunidad: obra.comunidad.toUpperCase() },
            ],
            [
              { ambito: "NACIONAL" },
              { comunidad: obra.comunidad.toUpperCase() },
            ],
            (filtros.nacional && [{ ambito: "NACIONAL" }]) || "",
          ],
          categorias: capitulo.categoria.id,
        },
      };
      if (filtros?.web) {
        query._where["web_contains"] = "h";
      }

      if (filtros?.score) {
        query._where["score_null"] = false;
      }

      if (filtros?.tipo_proveedor && filtros?.tipo_proveedor.length > 0 ) {
        query._where["tipo_proveedor_in"] = filtros?.tipo_proveedor;
      }
      const finalQuery = qs.stringify(query);

      // de ambito provincial
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/proveedors?${finalQuery}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }

      const data = await res.json();

      const tengo = ofertas.map((d) => d && d.proveedor && d.proveedor.id);

      // let filtrado = [...data, ...datanac, ...dataccaa];
      let filtrado = [...data];

      if (tengo.length > 0) {
        filtrado = [...filtrado.filter((c) => !tengo.includes(c.id))];
      }

      filtrado = filtrado.map((f) => ({ ...f, search: "white" }));

      const jdoUserData = JSON.parse(localStorage.getItem("jdo-userData"));
      const { empresa } = jdoUserData;
      const blockedProviders = await searchBlockedProviders(empresa);
      const list = blockedProviders?.[0]?.proveedors?.map((p) => {
        return `${p.id}`;
      });

      setListBlockedProviders(list);
      if (list !== undefined && list?.length > 0) {
        filtrado = filtrado.map((f) =>
          !list.includes(`${f.id}`) ? f : { ...f, blocked: true }
        );
      }

      // filtrado = filtrado.filter(
      //   (f) => filtros.provincia.includes(f.provincia));

      if (!filtros.nacional) {
        filtrado = filtrado.filter(
          (f) =>
            filtros.provincia.includes(f.provincia) ||
            f?.comunidad?.includes(obra.comunidad)
        );
      }

      setProveedores(filtrado);
    } catch (err) {
      console.error(`${t("error")}`, err);
    }
  };

  const saveFiltro = async (id, filtro) => {
    if (id) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/filtros/${id}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              provincia: JSON.stringify(filtro.provincia),
              ambito: filtro.ambito,
              tipo_proveedores: JSON.stringify(filtro.tipo_proveedor),
              score: filtro.score,
              web: filtro.web,
              capitulo: capitulo.id,
              obra: obra.id,
              nacional: filtro.nacional,
            }),
          }
        );
      } catch (error) {
        console.error("error :>> ", error);
      }
    } else {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/filtros`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            provincia: JSON.stringify(filtro.provincia),
            ambito: filtro.ambito,
            tipo_proveedores: JSON.stringify(filtro.tipo_proveedor),
            score: filtro.score,
            web: filtro.web,
            capitulo: capitulo.id,
            obra: obra.id,
            nacional: filtro.nacional,
          }),
        });
        const filtro_guardado = await res.json();
        setFiltroRecuperado(filtro_guardado.id);
      } catch (error) {
        console.error("error :>> ", error);
      }
    }
  };

  useEffect(() => {
    if (filtros) {
      getFilteredProveedores();
    }
  }, [filtros]);

  useEffect(() => {
    if (ofertas) {
      getFilters();
    }
  }, [ofertas]);

  useEffect(() => {
    setOfertas(null);
    getAllProvincias();
    if (capitulo) {
      (async () => {
        getOfertas();
      })()
    } else {
      setFiltros(null);
      setFiltroRecuperado(null);
      setProveedores([]);
    }
  }, [capitulo]);

  useEffect(() => {
    setOfertas(null);
    if (capitulo) {
      getOfertas();
    }
  }, [oferta]);

  // ************************** FUNCTIONS
  const toggle = () => setModal(!modal);

  const handleAdd = async (newProveedor) => {
    if (newProveedor?.blocked) {
      return;
    }

    const newData = { capitulo, proveedor: newProveedor.id };
    const res = await fetch(`${process.env.REACT_APP_API_URL}/ofertas`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();

    dispatch({
      origin: "app",
      type: "SET_OFERTA",
      oferta: data,
    });

    setProveedor(null);
    getOfertas();
  };

  useEffect(() => {
    if (proveedor) {
      handleAdd(proveedor);
    }
  }, [proveedor]);

  const handleDelete = async (id) => {
    const deleteOffer = await getOfferByID({ ID: id });
    const providerWithDelegations = await getProviderByID(
      deleteOffer?.proveedor?.id
    );
    const { delegacions } = providerWithDelegations;
    const emailsDelegations = delegacions?.map((d) => d.email);
    const chapter = await getChapterByID(capitulo?.id);
    let emailsToDelete = [
      deleteOffer?.proveedor?.email,
      deleteOffer?.proveedor?.email_contacto,
      ...emailsDelegations,
    ];
    emailsToDelete = emailsToDelete?.filter((e) => e !== null ?? e);
    let newsEmailsToChapter = JSON.parse(chapter?.emails);
    newsEmailsToChapter = newsEmailsToChapter?.filter((e) =>
      !emailsToDelete.includes(e) ? e : null
    );
    newsEmailsToChapter = newsEmailsToChapter?.filter((e) => e !== null ?? e);
    const res = await fetch(`${process.env.REACT_APP_API_URL}/ofertas/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
        "Content-Type": "application/json",
      },
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }

    if (res.status === 200) {
      setEmailsToSend(newsEmailsToChapter);
      await updateChapter({
        id: capitulo?.id,
        items: { emails: JSON.stringify(newsEmailsToChapter) },
      });
    }
    setProveedor(null);
    getOfertas();
  };

  const handleUpdate = async (id, status, item) => {
    let data = item;
    switch (status) {
      case "rejected":
        data["aceptacion"] =
          item.aceptacion === null ? moment().format("YYYY-MM-DD") : null;
        data["recepcion"] = null;
        data["rechazada"] = moment().format("YYYY-MM-DD");
        break;
      case "received":
        data["recepcion"] =
          item.recepcion === null ? moment().format("YYYY-MM-DD") : null;
        data["aceptacion"] = null;
        data["rechazada"] = null;
        break;
      case "hired":
        data["contratado"] =
          item.contratado === null ? moment().format("YYYY-MM-DD") : null;
        data["aceptacion"] = null;
        data["rechazada"] = null;
        break;
      case "noHired":
        data["contratado"] = null;
        data["aceptacion"] = null;
        data["rechazada"] = null;
        break;
      default:
        data["recepcion"] = null;
        data["aceptacion"] = null;
        data["rechazada"] = null;
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}/ofertas/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    setProveedor(null);
    getOfertas();
  };

  const handleAddAll = async () => {
    setIsGettingTodos(true);
    let todos = [];
    if (proveedores) {
      for (const prov of proveedores) {
        if (!prov?.blocked) {
          const newData = { capitulo, proveedor: prov.id };
          const res = fetch(`${process.env.REACT_APP_API_URL}/ofertas`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          });
          todos.push(res);
        }
      }

      try {
        await Promise.all(todos);
        await getOfertas();
        setIsGettingTodos(false);
      } catch (error) {
        toast.error("error");
        setIsGettingTodos(false);
      }
    }
  };

  const getDocumentation = async (id) => {
    if (id === null) return { epigraphs_work: "", blueprints: "" };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/documentacion-de-la-obras/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
      if (!data) return { epigraphs_work: "", blueprints: "" };
      return data;
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  const getCorreoOferta = async () => {
    const { estado_obra } = obra;
    const URL = process.env.REACT_APP_API_URL;
    const user =
      !_.isNull(localStorage.getItem("jdo-userData")) &&
      JSON.parse(localStorage.getItem("jdo-userData")).id;
    try {
      const res = await fetch(
        `${URL}/correo-para-ofertas/?users_permissions_user=${user}&state_work=${estado_obra}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!res.ok) {
        toast.error(`${t("login_error")}`);
      } else {
        const response = await res.json();

        if (response.length > 0) {
          const { contents } = response[0];
          return contents;
        }
        return `<p>Buenos días:</p> <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estamos trabajando en la ejecució de la obra {{Title}} en {{Province}} y nos disponemos a iniciar los trabajos de contratación del capítulo de {{ChapterName}}.</p> <p>Les enviamos las mediciones y epígrafes con la intención de solicitarles su mejor oferta.</p> <p>Si necesitan información adicional, como mediciones desglosadas o planos, no dude en contactar con nosotros.</p> <p>Dirección de la obra: {{Address}}</p> <p></p> <p>Muchas gracias</p> <p>Un saludo</p> <p>&nbsp;</p>`;
      }
    } catch (error) {
      toast.error(`${t("login_error")}`);
    }
  };

  const enviaCorreos = async () => {
    const { documentacion_obra } = obra;

    const correo = await getCorreoOferta();

    const documentation = await getDocumentation(documentacion_obra);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/ofertas/?capitulo_eq=${capitulo.id}&realizacion_null=t`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }

      await res.json();
      const me = JSON.parse(localStorage.getItem("jdo-userData")).email;
      const emails = JSON.parse(localStorage.getItem("emails"));
      const chapter = await getChapterByID(capitulo?.id);
      let chapterEmails = JSON.parse(chapter?.emails);
      if (!chapterEmails) {
        chapterEmails = [];
      }
      let allProvidersEmails = [...chapterEmails];
      allProvidersEmails = allProvidersEmails.filter((e) => e !== null ?? e);
      let cca = emails.map((e) => (!allProvidersEmails.includes(e) ? e : null));
      cca = cca.filter((e) => e !== null ?? e);
      const cc = cca.join("; ");
      const emailKeys = {
        "{{ChapterName}}": capitulo.categoria.nombre,
        "{{Builder}}": obra.constructora,
        "{{Title}}": obra.titulo,
        "{{Province}}": obra.provincia,
        "{{Address}}": obra.direccion,
        "{{Epigraphs}}": documentation.epigraphs_work,
        "{{Blueprints}}": documentation.blueprints,
        "{{Manager}}": obra.encargado,
        "{{PhoneManager}}": obra.telefono_encargado,
      };
      let bodyEmail = correo;
      Object.keys(emailKeys).forEach((key) => {
        bodyEmail = bodyEmail.replace(key, emailKeys[key]);
      });
      bodyEmail = bodyEmail.replaceAll(",", "");
      bodyEmail = bodyEmail.replace(/<style([\s\S]*?)<\/style>/gi, "");
      bodyEmail = bodyEmail.replace(/<script([\s\S]*?)<\/script>/gi, "");
      bodyEmail = bodyEmail.replace(/<\/div>/gi, "\n");
      bodyEmail = bodyEmail.replace(/<\/li>/gi, "\n");
      bodyEmail = bodyEmail.replace(/<li>/gi, "  *  ");
      bodyEmail = bodyEmail.replace(/<\/ul>/gi, "\n");
      bodyEmail = bodyEmail.replace(/<\/p>/gi, "\n");
      bodyEmail = bodyEmail.replace(/<br\s*[\/]?>/gi, "\n");
      bodyEmail = bodyEmail.replace(/&s*[\/]?;/gi, " ");
      bodyEmail = bodyEmail.replace(/&nbsp;/g, " ");
      bodyEmail = bodyEmail.replace(/<a.*?(href=\x22.*?\x22).*?>/g, "File: $1");
      bodyEmail = bodyEmail.replace("href=", "");
      bodyEmail = bodyEmail.replace(
        /<img.*?(src=\x22.*?\x22).*?\/>/g,
        "File: $1"
      );
      bodyEmail = bodyEmail.replace("src=", "");
      bodyEmail = bodyEmail.replace(/<[^>]+>/gi, "");
      bodyEmail = bodyEmail.replaceAll("null", "");

      bodyEmail = encodeURI(bodyEmail);

      if (cc !== "") {
        window.location.href = `mailto:?bcc=${cc}&subject=Petici%C3%B3n%20de%20oferta.%20${capitulo.categoria.nombre}&body=${bodyEmail}`;
        await actualizaFechasEnvio();
        await getOfertas();
        allProvidersEmails = [...allProvidersEmails, ...cca];
        await updateChapter({
          id: capitulo?.id,
          items: { emails: JSON.stringify(allProvidersEmails) },
        });
      } else {
        toast.error(`${t("no_hay_destinatarios")}`);
      }
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  const actualizaFechasEnvio = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/ofertas/${capitulo.id}/envios`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      getOfertas();
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  const manageProvincia = (cual, accion) => {
    if (filtros) {
      const prev = { ...filtros };
      if (accion === "add") {
        const provincias = [...prev.provincia];
        const nuevo = {
          ...prev,
          provincia: [...provincias, cual.toUpperCase()],
        };
        setFiltros(nuevo);
        if (filtroRecuperado) {
          saveFiltro(filtroRecuperado, nuevo);
        }
      } else {
        const provincias = [...filtros.provincia];
        const sin = provincias.filter(
          (p) => p.toUpperCase() !== cual.toUpperCase()
        );
        const nuevo = { ...prev, provincia: [...sin] };
        setFiltros(nuevo);
        if (filtroRecuperado) {
          saveFiltro(filtroRecuperado, nuevo);
        }
      }
    }
  };

  const manageTipoProveedor = (cual, accion) => {
    if (filtros) {
      const prev = { ...filtros };

      if (accion === "add") {
        const proveedores = [...prev.tipo_proveedor];
        const nuevo = { ...prev, tipo_proveedor: [...proveedores, cual] };
        setFiltros(nuevo);
        if (filtroRecuperado) {
          saveFiltro(filtroRecuperado, nuevo);
        }
      } else {
        const proveedores = [...prev.tipo_proveedor];
        const sin = proveedores.filter((p) => p !== cual);
        const nuevo = { ...prev, tipo_proveedor: [...sin] };
        setFiltros(nuevo);
        if (filtroRecuperado) {
          saveFiltro(filtroRecuperado, nuevo);
        }
      }
    }
  };

  const manageNacionales = (cual) => {
    if (filtros) {
      const prev = { ...filtros };
      const nuevo = { ...prev, nacional: cual };
      setFiltros(nuevo);
      if (filtroRecuperado) {
        saveFiltro(filtroRecuperado, nuevo);
      }
    }
  };

  const manageWeb = (cual) => {
    if (filtros) {
      const prev = { ...filtros };
      const nuevo = { ...prev, web: cual };
      setFiltros(nuevo);
      if (filtroRecuperado) {
        saveFiltro(filtroRecuperado, nuevo);
      }
    }
  };

  const manageScore = (cual) => {
    if (filtros) {
      const prev = { ...filtros };
      const nuevo = { ...prev, score: cual };
      setFiltros(nuevo);
      if (filtroRecuperado) {
        saveFiltro(filtroRecuperado, nuevo);
      }
    }
  };

  // ************************** FUNCTIONS

  if (!datos) {
    return <div>Loading...</div>;
  }

  const handleFilterChange = (event) => {
    const needle = event.target.value.toUpperCase();
    setValueOfInput(event.target.value);
    setFiltered(needle);
    setChangeFilter(needle);
  };

  const processChangeScopeModal = (ID) => {
    setShowChangeScopeModal(true);
    setProviderToShow(ID);
  };

  const handleOnSearch = async () => {
    setIsLoading(true);
    const needle = filtered;
    const providersInSearch = [];
    const offertsInSearch = [];
    const providers = proveedores.map((p) => {
      if (needle.length === 0) return { ...p, search: "white" };
      if (needle === "") return { ...p, search: "white" };
      if (needle === " ") return { ...p, search: "white" };
      if (p?.nombre.includes(needle)) {
        providersInSearch.push(p.id);
        return { ...p, search: "lightblue" };
      }
      return { ...p, search: "white" };
    });

    setProveedores(providers);

    if (!ofertas) {
      return;
    }

    if (isLoading) {
      return;
    }

    if (providersInSearch.length < 1) {
      const offerts = ofertas.map((o) => {
        if (needle.length === 0) return { ...o, search: "white" };
        if (needle === "") return { ...o, search: "white" };
        if (needle === " ") return { ...o, search: "white" };
        if (o?.proveedor?.nombre.includes(needle)) {
          offertsInSearch.push(o.id);
          return { ...o, search: "lightblue" };
        }
        return { ...o, search: "white" };
      });
      setOfertas(offerts);
    }

    if (offertsInSearch.length >= 1) {
      toast.info(`${t("El proveedor ya esta en la columna 4")}`);
    }

    if (offertsInSearch.length === 0 && providersInSearch.length === 0) {
      let provider = null;

      // TODO: split this code
      provider = await handleSearchProviderWhileLoading(needle);
      const responseIfExist = handleNotFountProvider(provider);

      let excludingFilter = false;
      let notHaveCategory = false;
      let notValoredProvider = false;
      let outsideCommunity = false;
      if (responseIfExist) {
        if (filtros?.score) {
          notValoredProvider = handleNotValoredProvider(provider);
          setIsLoading(false);
        }
        if (!filtros?.nacional) {
          excludingFilter = handleExcludingFilter(provider);
          setIsLoading(false);
        }
        if (!excludingFilter && !filtros?.score) {
          notHaveCategory = await handleNotHaveCategory(provider);
          setIsLoading(false);
        }
        if (!notHaveCategory && !excludingFilter && !notValoredProvider) {
          outsideCommunity = await handleOutsideCommunity(provider);
          setIsLoading(false);
        }
        if (
          !notHaveCategory &&
          !excludingFilter &&
          !outsideCommunity &&
          !filtros?.score
        ) {
          handleNotMatchProviderType(provider);
          setIsLoading(false);
        }
      }
      if (!responseIfExist && !isLoading) {
        getEInformaData(valueOfInput);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const handleNotValoredProvider = (provider) => {
    if (
      provider?.score === null ||
      provider?.score === undefined ||
      provider?.score === ""
    ) {
      setShowChangeScopeModal(true);
      setMessageModel(
        `Proveedor no encontrado, revisa los criterios de búsqueda de la columna 2`
      );
      setTypeScopeModal("excludingFilter");
      return true;
    }
    return false;
  };

  const handleNotMatchProviderType = (provider) => {
    const tipoProveedor = provider?.tipo_proveedor?.id;
    if (!filtros?.tipo_proveedor.includes(tipoProveedor)) {
      setShowChangeScopeModal(true);
      setMessageModel(
        `Revisa los criterios de búsqueda de la columna 2, por tipo de proveedor: Distribuidor, Fabricante o Subcontratista`
      );
      setTypeScopeModal("excludingFilter");
      return true;
    }
    return false;
  };

  const handleSearchProviderWhileLoading = async (needle) => {
    let provider = null;
    const providersByName = await searchByName(needle);
    provider = listProviders.filter((r) => {
      return r?.nombre?.includes(needle) ?? r;
    })[0];
    if (provider === null || provider === undefined) {
      provider = providersByName?.filter((r) => {
        return r?.nombre?.toUpperCase()?.includes(needle) ?? r;
      })[0];
    }
    return provider;
  };

  const handleResetChangeFilter = () => {
    setChangeFilter("");
  };

  const handleNotFountProvider = (provider) => {
    if (!provider || provider === undefined) {
      setShowNewModal((prevState) => !prevState);
      /*processChangeScopeModal();
      setTypeScopeModal("notFound");
      setMessageModel(`Proveedor no encontrado.`);*/
      return false;
    }
    return true;
  };

  const handleNotHaveCategory = async (provider) => {
    const categoryID = app?.capitulo?.categoria?.id;
    const categoryName = app?.capitulo?.categoria?.nombre;
    const responseCategories = await getCategoryById(categoryID);
    const providersCategories = responseCategories?.proveedors;
    const providerID = provider?.id;
    const providerName = provider?.nombre;
    const includeId = providersCategories
      .map((rc) => providerID === rc?.id)
      .includes(true);
    if (!includeId) {
      setMessageModel(
        `El proveedor "${providerName}" no tiene seleccionada la categoría "${categoryName}"`
      );
      setTypeScopeModal("notHaveCategory");
      processChangeScopeModal(providerID);
      return true;
    }
    return false;
  };

  const handleOutsideCommunity = async (provider) => {
    const province = provider?.provincia;
    let community = communities.map((c) =>
      c?.provincias
        ?.map((p) =>
          province?.toUpperCase() === p?.nombre?.toUpperCase() ? true : false
        )
        .includes(true)
        ? c
        : undefined
    );
    community = community.filter((c) => c !== undefined)[0];
    let withinCommunity = community?.provincias
      ?.map((p) =>
        obra?.provincia?.toUpperCase() === p?.nombre.toUpperCase()
          ? true
          : false
      )
      .includes(true);
    const categoryID = app?.capitulo?.categoria?.id;
    let withinCategory = provider?.categorias
      ?.map((c) => (c.id === categoryID ? true : false))
      .includes(true);
    const providerID = provider?.id;
    if (!withinCommunity && withinCategory) {
      // toast.info(`Proveedor fuera de la comunidad autonoma de la obra, selecciona su provincia ${province} O cambia el ámbito
      // del proveedor a Nacional`);
      setMessageModel(`Proveedor fuera de la comunidad autonoma de la obra, selecciona su provincia ${province} O cambia el ámbito
      del proveedor a Nacional`);
      setTypeScopeModal("outsideCommunity");
      processChangeScopeModal(providerID);
      return true;
    }
    return false;
  };

  const handleExcludingFilter = (provider) => {
    if (provider.ambito !== "NACIONAL") {
      return false;
    }
    setShowChangeScopeModal(true);
    setMessageModel(
      `Elimina el filtro de excluir nacionales fuera de la comunidad`
    );
    setTypeScopeModal("excludingFilter");
    return true;
  };

  const getEInformaData = async (empresa) => {
    const getFromEmpresa = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_API_EINFORMA}/companies?companySearch=${empresa}&rows=20`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${await getEInformaToken()}`,
          },
        }
      );
      const data = await res.json();
      setDatosInforma(data.empresa);
    };

    try {
      getFromEmpresa();
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleOnSearch();
    }
  };

  if (datos) {
    return (
      <div className={"row"}>
        {isGettingTodos && (
          <Flex alignItems="center" justifyContent="center" p={4}>
            <h5>Cargando proveedores...</h5>
          </Flex>
        )}

        <div className={"col-3"}>
          <div className="item-list proveedores-filtro">
            <div
              className={"category-box is-small"}
              style={{ fontSize: "0.9rem" }}
            >
              2. Criterios de búsqueda
            </div>
            <Box mt={3}>
              <strong>{t("Filtros de selección")}</strong>
            </Box>

            <Box mt={3}>
              {!filtros || (filtros && filtros.nacional === true) ? (
                <div
                  className={"item-filtro"}
                  onClick={() => manageNacionales(false)}
                >
                  {"Excluir nacionales fuera comunidad"}
                </div>
              ) : (
                <div
                  className={"item-filtro selected"}
                  onClick={() => manageNacionales(true)}
                >
                  {"Excluir nacionales fuera comunidad"}
                </div>
              )}
            </Box>

            <Box mt={3}>
              <div
                className={
                  "item-filtro " + (filtros && filtros.web && "selected")
                }
                onClick={() =>
                  manageWeb(filtros && filtros.web ? !filtros.web : true)
                }
              >
                {"Con web"}
              </div>
            </Box>

            <Box mt={3}>
              <div
                className={
                  "item-filtro " + (filtros && filtros.score && "selected")
                }
                onClick={() =>
                  manageScore(filtros && filtros.score ? !filtros.score : true)
                }
              >
                {"Con valoración"}
              </div>
            </Box>

            <Box mt={3}>
              {tipoProveedores.length > 0 ? (
                tipoProveedores.map((d, index) => {
                  if (
                    filtros &&
                    filtros.tipo_proveedor.find((p) => p === d.id)
                  ) {
                    return (
                      <div
                        key={index}
                        className={"item-filtro selected"}
                        onClick={() => manageTipoProveedor(d.id, "remove")}
                      >
                        {d.nombre}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        className={"item-filtro"}
                        onClick={() => manageTipoProveedor(d.id, "add")}
                      >
                        {d.nombre}
                      </div>
                    );
                  }
                })
              ) : (
                <div style={{ textAlign: "center" }}>{t("-")}</div>
              )}
            </Box>

            <Box mt={3}>
              {provincias.length > 0 ? (
                provincias.map((d, index) => {
                  if (
                    filtros &&
                    filtros.provincia.find(
                      (p) => p.toUpperCase() === d.label.toUpperCase()
                    )
                  ) {
                    return (
                      <div
                        key={index}
                        className={"item-filtro selected"}
                        onClick={() => manageProvincia(d.label, "remove")}
                      >
                        {d.label}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        className={"item-filtro"}
                        onClick={() => manageProvincia(d.label, "add")}
                      >
                        {d.label}
                      </div>
                    );
                  }
                })
              ) : (
                <div style={{ textAlign: "center" }}>{t("-")}</div>
              )}
            </Box>
          </div>
        </div>

        <div className={"col-4"}>
          <div className="item-list">
            <div
              className={"category-box is-medium"}
              style={{ fontSize: "0.9rem" }}
            >
              3. Proveedores sugeridos
            </div>
            <hr />
            <Row
              style={{ margin: 0, padding: 0 }}
              className={"justify-content-center"}
            >
              <Col xxl={"8"} xl={"8"} lg={"8"} md={"8"} sm={"9"} xs={"12"}>
                <div style={{ position: "relative" }}>
                  <div>
                    <Form.Control
                      name="filter"
                      type="text"
                      value={changeFilter}
                      onChange={handleFilterChange}
                      onKeyDown={handleKeyDown}
                      placeholder={t("findProvider")}
                    />
                  </div>
                  <div style={{ position: "absolute", top: "5%", left: "80%" }}>
                    <X
                      width="35px"
                      height="35px"
                      fill="gray"
                      onClick={() => {
                        handleResetChangeFilter();
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col xxl={"4"} xl={"4"} lg={"4"} md={"4"} sm={"3"} xs={"12"}>
                <Button
                  variant="secondary"
                  onClick={handleOnSearch}
                  disabled={!proveedores || isLoading}
                >
                  Buscar
                </Button>
              </Col>
            </Row>
            <hr />
            <Box mt={3} mb={3} style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                onClick={handleAddAll}
                disabled={!proveedores || proveedores.length === 0}
              >
                Añadir todos
              </Button>
            </Box>
            {proveedores ? (
              proveedores.length > 0 ? (
                proveedores.map((d, index) => (
                  <div key={index} onClick={() => handleAdd(d)}>
                    <ObrasProveedoresSeleccionItem
                      item={d}
                      final={final}
                      doDelete={handleDelete}
                    />
                  </div>
                ))
              ) : (
                <div style={{ textAlign: "center" }}>{t("")}</div>
              )
            ) : (
              <div
                style={{
                  textAlign: "center",
                  padding: "15px 0px",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {t("")}
              </div>
            )}
          </div>
        </div>

        <div className={"col-5"}>
          <div className="item-list">
            <div
              className={"category-box is-medium"}
              style={{
                fontSize: "0.9rem",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              4. Proveedores por envío de solicitud
              <TableContent
                datos={allData}
                obra={obra}
                allDataToUsersDuty={allDataToUsersDuty}
              />
            </div>
            <Row className={"mt-3 justify-content-center"}>
              <Col xxl={"6"} xl={"6"} lg={"6"} md={"6"} sm={"12"} xs={"12"}>
                <Box style={{ textAlign: "left" }}>
                  <LinkContainer
                    to={`/me/correo-ofertas/${estado_obra}`}
                    disabled={!capitulo}
                  >
                    <Nav.Link className={"nav-link"}>Correo Oferta</Nav.Link>
                  </LinkContainer>
                </Box>
              </Col>
              <Col xxl={"6"} xl={"6"} lg={"6"} md={"6"} sm={"12"} xs={"12"}>
                <Box style={{ textAlign: "right" }}>
                  {isOferta && (
                    <Button m="1" onClick={enviaCorreos} disabled={!capitulo}>
                      {t("enviar_oferta")}
                    </Button>
                  )}
                </Box>
              </Col>
            </Row>
            {ofertas ? (
              ofertas.length > 0 ? (
                <div className="accordion" id="accordionExample">
                  {ofertas.map((d, i) => (
                    <ObrasProveedoresListItem
                      key={d.id + "-" + i}
                      item={d}
                      final={final}
                      doDelete={handleDelete}
                      doUpdate={handleUpdate}
                    />
                  ))}
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>{t("")}</div>
              )
            ) : (
              <div
                style={{
                  textAlign: "center",
                  padding: "15px 0px",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {t("")}
              </div>
            )}
          </div>
        </div>
        <ChangeScopeModal
          show={showChangeScopeModal}
          providerID={providerToShow}
          message={messageModel}
          doClose={handleCloseChangeScopeModal}
          typeInternalModal={typeScopeModal}
          category={category}
        />
        <ModalOfEInforma
          show={showNewModal}
          datos={datoseInforma}
          doClose={handleCloseModalInforma}
          fullscreen={fullscreenModal}
          categoria={capitulo?.categoria?.id}
          title={`"${filtered}" no está en la base de datos de Wikiobra, pero puedes añadirla seleccionando la empresa del listado siguiente`}
        />
      </div>
    );
  }
};

export default ObrasProveedoresList;
