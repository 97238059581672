import * as _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Flex } from "reflexbox";
import { ThemeContext } from "styled-components";
import DeleteModal from "../../common/DeleteModal";
import useAppContext from "../../data/store";
import { MainTitle } from "../../common/StyledComponents";
import { Button } from "../../components";

const DelegacionesFullList = ({ proveedor_id }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [datos, setDatos] = useState(null);
  const [count, setCount] = useState(0);
  const [{ user }] = useAppContext();
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [provincia, setProvincia] = useState("");
  const [provincias, setProvincias] = useState([]);
  const [reset, setReset] = useState(false);
  const [display, setDisplay] = useState(false);
  const [tag, setTag] = useState("");

  const [buscamos, setBuscamos] = useState(
    localStorage.getItem("jdo-filtro-proveedor")
  );
  const [cargando, setCargando] = useState(true);
  const [cif, setCif] = useState(
    localStorage.getItem("jdo-filtro-proveedor-cif") || ""
  );

  useEffect(() => {
    const role =
      !_.isNull(localStorage.getItem("jdo-userData")) &&
      JSON.parse(localStorage.getItem("jdo-userData")).role.name;
    const isAdmin = (role && role === "Administrador") || user.isAdmin;
    setIsAdmin(isAdmin);

    getData(proveedor_id);

    getAllProvincias();
  }, [proveedor_id]);

  const getData = async (proveedore_id) => {
    if (proveedor_id === null || proveedor_id === undefined || /[a-zA-Z]/g.test(proveedor_id)) {
      const delegationsTemp = JSON.parse(localStorage.getItem('delegationsTemp'));
      if (delegationsTemp !== null) {
        setDatos(delegationsTemp);
        setCount(delegationsTemp.length);
      } else {
        setDatos([]);
        setCount(0);
      }
      return;
    }
    try {
      //
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/delegacions/?proveedor.id=${proveedor_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
      setDatos(data);
      setCount(data.total);
    } catch (err) {
      console.error("err :>> ", err);
    } finally {
      setTag("");
      setCargando(false);
    }
  };

  const getAllProvincias = async () => {
    const token = localStorage.getItem("jdo-userToken");

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/provincias?_limit=-1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const provinciasProveedores = await res.json();
      const cadaProvincia = provinciasProveedores
        .map((proveedor) => ({
          label: proveedor.nombre,
          value: proveedor.nombre,
        }))
        .filter((f) => f.value != null && f.value !== "");

      const provinciasOrden = _.sortBy(cadaProvincia, (o) => {
        const label = _.deburr(o.label);
        return label;
      });

      setProvincias(provinciasOrden);
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  };

  // ************************** FUNCTIONS
  const toggle = () => setModal(!modal);

  const handleSelectedProvincia = (selected) => {
    if (!history.location.state) {
      localStorage.setItem("jdo-filtro-proveedor-pagina", "0");
    }
    if (selected) {
      setProvincia(selected);
      localStorage.setItem(
        "jdo-filtro-admin-provincia",
        JSON.stringify(selected)
      );
    }
    setReset(false);
  };

  const handleClose = () => setShow(false);

  const handleConfirm = async () => {
    if (proveedor_id === null || proveedor_id === undefined) {
      const delegationsTemp = JSON.parse(localStorage.getItem('delegationsTemp'));
      const newDelegationsTemp = delegationsTemp.filter((d) => d.id !== id ?? d);
      localStorage.setItem('delegationsTemp', JSON.stringify(newDelegationsTemp));
      setId(null);
      setShow(false);
      getData(proveedor_id);
      return;
    }
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/delegacions/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      setId(null);
      getData(proveedor_id);
    } catch (err) {
      toast.error(`${t("error")}`);
    }

    setShow(false);
  };

  const handleDelete = (id) => {
    setId(id);
    setShow(true);
  };

  if (!datos) {
    return null;
  } else if (datos) {
    return (
      <Container>
        <Row>
          <Col>
            <div className={"card p-3"}>
              <div className={"card-title"}>
                <div className={"d-flex justify-content-between"}>
                  <MainTitle>{t("Delegaciones")}</MainTitle>
                  <Button
                    text={t("Nueva delegación")}
                    className={"btn-primary"}
                    icon={{ prefix: "fas", iconName: "plus" }}
                    onClick={() =>
                      history.push(`/delegaciones/${proveedor_id}/new`)
                    }
                  />
                </div>
              </div>
              <div className={"card-body pt-0"}>
                {datos.length === 0 ? (
                  <div className={"d-flex justify-content-between"}>
                    {t("no_data")}
                  </div>
                ) : (
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    style={{ width: "100%", fontSize: "12px" }}
                  >
                    <thead>
                      <tr>
                        <th>Provincia</th>
                        <th>Contacto</th>
                        <th>Teléfono</th>
                        <th>Email</th>
                        <th>Teléfono oficina</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datos.map((d) => (
                        // <ProveedoresListItem key={d.id} item={d} />
                        <tr key={d.id}>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (isAdmin) {
                                history.push(
                                  `/delegaciones/${proveedor_id}/edit/${d.id}`
                                );
                              }
                            }}
                          >
                            {d.provincia}
                          </td>
                          <td>{d.contacto}</td>
                          <td>{d.telefono}</td>
                          <td>{d.email}</td>
                          <td>{d.tel_oficina}</td>
                          <td align="right">
                            {isAdmin && (
                              <Flex justifyContent="end">
                                <Box>
                                  <Button
                                    text={""}
                                    className={
                                      "btn-outline-danger btn-sm w-100 mb-2 min-47"
                                    }
                                    icon={{ prefix: "fas", iconName: "trash" }}
                                    onClick={() => handleDelete(d.id)}
                                  />
                                </Box>
                              </Flex>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}

                <DeleteModal
                  show={show}
                  body={`${t("estas_seguro_de_borrar")} ${t("la delegación")}`}
                  doClose={handleClose}
                  doConfirm={handleConfirm}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default DelegacionesFullList;
