import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import * as moment from "moment";
import { Box, Flex } from "reflexbox";
import { toast } from "react-toastify";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Error from "../../common/Error";
import OfertasValorarForm from "./OfertasValorarForm";

const OfertasValorar = () => {
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const [participant, setParticipant] = useState(null);
  const [obra, setObra] = useState(null);
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("jdo-userData"));

  useEffect(() => {
    if (participant?.proveedor?.nombre === user?.empresa) {
      toast.error(`Operación no permitida`);
      history.goBack();
      history.push('/ejecucion');
    }
  });


  const { id, participante, valoracion } = useParams();

  useEffect(() => {
    const getParticipante = async () => {
      try {
        const res = await fetch(
          // `${process.env.REACT_APP_API_URL}/ofertas/${oferta}`,
          `${process.env.REACT_APP_API_URL}/participantes/${participante}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        const data = await res.json();
        setParticipant(data);
      } catch (err) {
        toast.error(`${t("error")}`);
      }
    };
    const getObra = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/obras/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        const data = await res.json();
        setObra(data);
      } catch (err) {
        toast.error(`${t("error")}`);
      }
    };
    //
    if (participante) {
      getParticipante();
    }
    if (id) {
      getObra();
    }
  }, [id, valoracion]);

  if (!isLoading) {
    return (
      <div className="container">
        <Card style={{ marginTop: "10px", marginBottom: "20px", padding:"15px" }}>
          <div className="containers">
            <Flex>
              <Box flex="1">
                <Nombre>{obra?.titulo}</Nombre>
              </Box>
            </Flex>
            <Row style={{ marginBottom: "5px"}}>
              <Col>
                <Valor s>{obra?.constructora || "-"}</Valor>
                <Etiqueta>{t("constructora")} </Etiqueta>
              </Col>
              <Col>
                <Valor>
                  {(obra?.comunidad && obra?.comunidad.nombre) || "-"}
                </Valor>
                <Etiqueta>{t("comunidad")} </Etiqueta>
              </Col>
              <Col>
                <Valor>
                  {(obra?.provincia && obra?.provincia.nombre) || "-"}
                </Valor>
                <Etiqueta>{t("provincia")} </Etiqueta>
              </Col>
              <Col>
                <Valor>
                  {(obra?.municipio && obra?.municipio.nombre) || "-"}
                </Valor>
                <Etiqueta>{t("municipio")}</Etiqueta>
              </Col>
              <Col>
                <Valor>
                  {(obra?.fecha_final &&
                    moment(obra?.fecha_final).format("YYYY")) ||
                    "-"}
                </Valor>
                <Etiqueta>{t("año_final")}</Etiqueta>
              </Col>
            </Row>
            <Row style={{ marginBottom: "15px" }}>
              <Col>
                <Valor>
                  {(participant?.proveedor && participant.proveedor.nombre) ||
                    "-"}
                </Valor>
                <Etiqueta>{t("proveedor")} </Etiqueta>
              </Col>
              <Col>
                <Valor>
                  {(participant?.categoria && participant.categoria.nombre) ||
                    "-"}
                </Valor>
                <Etiqueta>{t("proveedor")}</Etiqueta>
              </Col>
            </Row>
          </div>
        </Card>
        <Card style={{ padding: "10px 0" }}>
          <OfertasValorarForm
            obra={obra}
            valoracion={valoracion}
            participante={participant?.id}
          />
        </Card>
      </div>
    );
  }
};

const Etiqueta = styled.div`
  font-size: 12px;
  padding: 0px;
  color:  black;
  margin:0px
  font-weight: 200 !important;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Valor = styled.div`
  font-size: 16px;
  border-bottom: black ${(props) => `1px solid ${props.theme.colors.palegrey}`};
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-weight: 400 !important;
  color: black;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Nombre = styled.div`
  font-size: 24px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.primary};
  width: 100%;
  padding: 10px 0px;
`;

export default OfertasValorar;
