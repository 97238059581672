export const getListAllProviders = async ({ limit = 20, start = 0 }) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(
      `${API_URL}/proveedors/?_limit=${limit}&_start=${start}`,
      {
        method: "GET",
        headers: {
          //  Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const getCountProviders = async () => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(`${API_URL}/proveedors/count`, {
      method: "GET",
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const searchProvidersByName = async ({ name }) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(`${API_URL}/proveedors/?nombre=${name}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const getProviderByID = async (ID) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(`${API_URL}/proveedors/${ID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const createProvider = async (data) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(`${API_URL}/proveedors`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const response = await res.json();
    return response;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const updateProvider = async ({ id, data }) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(`${API_URL}/proveedors/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const response = await res.json();
    return response;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const searchByName = async (name) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  let headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  if (token === undefined || token === null) {
    headers = {
      "Content-Type": "application/json",
    };
  }

  try {
    const res = await fetch(`${API_URL}/proveedors/search-by-name`, {
      method: "POST",
      headers,
      body: JSON.stringify({ name }),
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const response = await res.json();
    return response;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const handleLoadProviders = async () => {
  let limit = 400;
  let start = 0;
  let finish = false;
  let listProviders = [];
  const count = await getCountProviders();
  const listProviderJson = JSON.parse(localStorage.getItem("listProviders"));
  if (
    listProviderJson &&
    listProviderJson !== null &&
    listProviderJson !== undefined &&
    listProviderJson?.length > 0
  ) {
    listProviders = listProviderJson;
  }
  if (
    listProviderJson &&
    listProviderJson !== null &&
    listProviderJson !== undefined &&
    listProviderJson?.length === count
  ) {
    finish = true;
  }
  do {
    let response = [];
    response = await getListAllProviders({ limit, start });
    if (response?.length === 0) {
      finish = true;
    }
    start += 400;
    if (!finish) {
      response?.forEach((p) => {
        listProviders?.push(p);
      });
    }
  } while (!finish);
  localStorage.setItem("listProviders", JSON.stringify([]));
  localStorage.setItem("listProviders", JSON.stringify(listProviders));
};
