import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import FullEditForm from "./FullEditForm";
import { useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import { MainTitle } from "../../common/StyledComponents";
import DelegacionesFullList from "../delegaciones/DelegacionesFullList";
import { Col, Container, Row } from "react-bootstrap";
import { getEInformaToken } from "../../logic/einforma";

const FullEdit = () => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [dataEinforma, setDataEinforma] = useState(null);
  const [fromEinforma, setFromEinforma] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [proveedorId, setProveedorId] = useState(null);

  useEffect(() => {
    const { id, categoria } = params;

    const getEinformaData = async (id) => {
      try {
        setIsLoading(true);
        const res = await fetch(
          `${process.env.REACT_APP_API_EINFORMA}/companies/${id}/report`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${await getEInformaToken()}`,
            },
          }
        );

        const data = await res.json();

        const {
          denominacion,
          telefono,
          identificativo,
          domicilioSocial,
          cargoPrincipal,
          web,
          localidad,
          email,
        } = data;

        const provinciaFromEinforma = localidad.match(/\((.*)\)/) || []; // give localidad that is into parenthesis
        const tel1 = (telefono && [...telefono].shift()) || ""; // first phone in array
        const tel2 =
          (telefono && telefono.length > 1 && telefono.reverse()[1]) || ""; // last phone in array

        const processProvince = {
          "GERONA"      : "Girona",
          "LA CORUÑA"   : "A Coruña",
          "GUIPUZKOA"   : "Guipúzcoa",
          "GIPUZKOA"    : "Guipúzcoa",
          "BIZKAIA"     : "Vizcaya",
          "LÉRIDA"      : "Lleida",
          "ARABA/ÁLAVA" : "Álava",
          "TENERIFE"    : "Santa Cruz de Tenerife",
          "ORENSE"      : "Ourense",
          "PALMAS (LAS)": "Las Palmas",
          "RIOJA, LA"   : "La Rioja"
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/einforma-translations`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
            },
          }
        );

        const einformaTranslations = await response.json();

        const translatesInBackend = {};

        einformaTranslations.forEach((t) => {
          translatesInBackend[t.clave] = t.valor;
        });

        let fullProvince = '';
        if (undefined === processProvince[provinciaFromEinforma[1].toUpperCase()]) {
          fullProvince = provinciaFromEinforma[1].toUpperCase();
        }
        if (undefined !== processProvince[provinciaFromEinforma[1].toUpperCase()]) {
          fullProvince = processProvince[provinciaFromEinforma[1].toUpperCase()];
        }
        if (undefined !== translatesInBackend[provinciaFromEinforma[1].toUpperCase()]) {
          fullProvince = translatesInBackend[provinciaFromEinforma[1].toUpperCase()];
        }
        setDataEinforma({
          nombre: denominacion,
          cif: identificativo,
          tel1: tel1.toString(),
          tel2: tel2.toString(),
          direccion: domicilioSocial + ". " + localidad,
          contacto: cargoPrincipal,
          web: web && [...web].shift(),
          provincia: fullProvince,
          email: email,
        });

        setFromEinforma(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // toast.error(t("error"));
      }
    };

    const getData = async (id) => {
      if (id === null || id === 'null' || /[a-zA-Z]/g.test(id)) {
        return;
      }
      setProveedorId(id);
      setIsLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/proveedors/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          toast.error("Ocurrio un error.");
          console.error("Bad request");
        }
        if (res.status === 200) {
          const data = await res.json();
          delete data.ofertas;
          delete data.users;
          if (data.categorias) {
            const tm = data.categorias.map((i) => ({
              value: i.id,
              label: i.nombre,
            }));
            data.categoriasValores = [...tm];
            delete data.categorias;
          }
          setData(data);
          setFromEinforma(false);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        toast.error(`${t("error")}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      getData(id);
      getEinformaData(id);
    } else {
      setData({ categoriasValores: [] });
    }
  }, []);

  const handleSave = async (newData) => {
    const { id } = params;
    setIsLoading(true);
    if (newData.categoriasValores) {
      const categorias = newData.categoriasValores.map((c) => c.value);
      newData.categorias = categorias;
    } else {
      newData.categorias = null;
    }
    if (null !== newData.delegations) {
      delete newData.delegacions;
    }
    const create = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/proveedors`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newData),
        });
        if (res.status !== 200) {
          toast.error("Ocurrio un error.");
          console.error("Bad request");
        }
        if (res.status === 200) {
          const data = await res.json();
          toast.success(`${t("added_ok")}`);
          const user = JSON.parse(localStorage.getItem("jdo-userData"));
          const business = user?.empresa;
          await handleCreateDelegations(data.id);
          if (newData?.nombre === business) {
            window.location.href = '/';
          }
          history.push("/full", {
            return: Number(localStorage.getItem("jdo-filtro-proveedor-pagina")),
          });
        }
      } catch (err) {
        toast.error("Error al crear el proveedor. El proveedor ya existe.")
        console.error(err);
        history.push("/full", {
          return: Number(localStorage.getItem("jdo-filtro-proveedor-pagina")),
        });
      } finally {
        setIsLoading(false);
      }
    };
    const update = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/proveedors/${id}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          }
        );
        if (res.status !== 200) {
          toast.error("Ocurrio un error.");
          console.error("Bad request");
        }
        if (res.status === 200) {
          toast.success(`${t("updated_ok")}`);
          history.push("/full", {
            return: Number(localStorage.getItem("jdo-filtro-proveedor-pagina")),
          });
        }
      } catch (err) {
        toast.error(`${t("error")}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (id && !fromEinforma) {
      update();
    } else {
      create();
    }
  };

  const handleCreateDelegations = (id) => {
    let delegationsTemp = JSON.parse(localStorage.getItem('delegationsTemp'));
    if (delegationsTemp === null) {
      delegationsTemp = [];
    }
    if (delegationsTemp.length > 0) {
      delegationsTemp.forEach((d) => {
        delete d.id;
        d.proveedor = id;
        handleSaveDelegations(d);
      });
    }
  }

  const handleSaveDelegations = async (newData) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/delegacions`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "jdo-userToken"
            )}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newData),
        }
      );
      if (res.status !== 200) {
        toast.error("Ocurrio un error.");
        console.error("Bad request");
      }
      if (res.status === 200) {
        await res.json();
        toast.success("Delegación guardada.");
        localStorage.setItem('fullOldValues', JSON.stringify({}));
        localStorage.setItem('delegationsTemp', JSON.stringify([]));
      }
    } catch (err) {
      toast.error("Error al crear el proveedor. El proveedor ya existe.");
    }
  }//test to upload

  if (isLoading) {
    return <Loading />;
  }
  if (!isLoading) {
    return (
      <Container>
        <Row>
          <Col>
            <div className={"card p-3"}>
              <div className={"card-title"}>
                <div className={"d-flex justify-content-between"}>
                  <MainTitle>{t("empresa_proveedora")}</MainTitle>
                </div>
              </div>
              <div className={"card-body pt-0"}>
                <FullEditForm item={data || dataEinforma} doSave={handleSave} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <DelegacionesFullList proveedor_id={proveedorId} />
          </Col>
        </Row>
      </Container>
    );
  }
};

export default FullEdit;
