import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Partners = () => {

  const [images, setImages] = useState([]);

  useEffect(() => {

    const getImages = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/patrocinadors`,
          { method: 'GET' }
        );
        if (res.status !== 200) {
          console.error('Bad request');
        }
        const data = await res.json();
        setImages(data);
      } catch (err) {
        console.error('err :>> ', err);
      }
    }
    getImages();
  }, []);

  return (
    <section className={"partners"}>
      <Container>
        <Row>
          <Col xs={"12"} className={"text-center my-5"}>
            <h4>Partners</h4>
          </Col>
          {images.length > 0 && images.map((item, i) => (
            <Col xs={"12"} md={"3"} className={"text-center logo-partners mb-5"} key={i}>
              <Link to={{ pathname: item.url }} target="_blank">
                <img src={item.logo?.url} alt="Cimelsa" />
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
