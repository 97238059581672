import React, { useEffect, useState } from "react";

import { Card } from "react-bootstrap";
import * as _ from "lodash";

import { getToken } from "../../logic/logic";
import Pagination from "../../common/Pagination";
import ObrasListItem from "./ObrasListItem";
import SearchBar from "../../common/SearchBar";
import { getStatusWork } from "./status.work.service";
import { useLocation } from "react-router-dom";

const ObrasList = ({ isLogged, final, withListado }) => {
  const [modal, setModal] = useState(false);
  const [datos, setDatos] = useState([]);
  const [count, setCount] = useState(0);
  const [medida, setMedida] = useState(10);
  const [pagina, setPagina] = useState(0);
  const [buscamos, setBuscamos] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");


  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname !== currentLocation) {
      setCurrentLocation(pathname)
      handleResetPage();
    }
  }, [location]);


  React.useEffect(() => {
    setPaginaInicial(Number(localStorage.getItem("jdo-filtro-obra-pagina")));
  }, []);

  const getData = async (search, page, size) => {
    try {
      const busca = search ? search : buscamos;
      const start = page * size;
      setPagina(page);
      setMedida(size);
      getToken();

      const status = await getStatusWork();

      let statusId = 0;

      if ( final ) {
        const state = status.filter( ( element ) => element.type === 'ejecutada' )[0];
        statusId = state.id;
      } else {
        const state = status.filter( ( element ) => element.type === 'en-curso' )[0];
        statusId = state.id;
      }

      let sort = '';

      const location = window.location.pathname;

      if (location?.includes('obras')) {
        sort = 'fecha_final:DESC,titulo:ASC';
      } else {
        sort = 'fecha_inicio:DESC,titulo:ASC';
      }

      const newUser = JSON.parse(localStorage.getItem("jdo-userData"));

      let res = null;
      let count = null;

      res = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/obras/search?fecha_final_null=${!final}&estado_obra=${statusId}&user=${newUser?.id}&_start=${start}&_limit=${size}&_sort=${sort}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ busca }),
        }
      );

      const countResponse = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/obras/count-by-status/${statusId}/${newUser?.id}`
      );

      if (res.status !== 200) {
        console.error("Bad request");
      }

      if (countResponse.status !== 200) {
        console.error("Bad request");
      }

      const data = await res.json();


      const dataCountResponse = await countResponse.json();

      setCount(dataCountResponse);

      if (search !== '') {
        setCount(data.total);
      }

      setDatos(data.items);

    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  // ************************** FUNCTIONS
  const toggle = () => setModal(!modal);

  const handleDelete = async (item) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/obras/${item.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status !== 200) {
      console.error("Bad request");
    }
    getData("", pagina, medida);
  };

  const [paginaInicial, setPaginaInicial] = useState(1);
  const handlePaginateClick = (data) => {
    const { currentPage, pageLimit, totalPages, totalRecords } = data;
    if (!_.isNull(currentPage)) {
      localStorage.setItem(
        "jdo-filtro-obra-pagina",
        Number(currentPage).toString()
      );
      const buscando = localStorage.getItem("jdo-filtro-obra") || "";
      setBuscamos(buscando);
      setMedida(pageLimit);
      setPagina(currentPage);
      getData(buscando, currentPage, pageLimit);
    }
  };

  const handleResetPage = () => {
    const page = 0;
    localStorage.setItem(
      "jdo-filtro-obra-pagina",
      Number(page).toString()
    );
    setPagina(page);
  }

  const handleSearch = (busca) => {
    localStorage.setItem("jdo-filtro-obra", busca || "");
    // setBuscamos(busca || "")
    getData(busca ? busca : "", pagina, medida);
  };

  const handleReset = () => {
    localStorage.setItem("jdo-filtro-obra", "");
    getData("", pagina, medida);
    // setBuscamos("");
  };

  if (!datos) {
    return <div>Loading...</div>;
  }

  if (datos) {
    return (
      <>
        <SearchBar
          item={buscamos}
          doSearch={(busca) => handleSearch(busca)}
          doReset={handleReset}
        />
        <Card variant="sombra" className={"p-0"}>
          {datos.map((d) => (
            <ObrasListItem
              key={d.id}
              item={d}
              final={final}
              withListado={withListado}
              doDelete={handleDelete}
            />
          ))}
          <div className={"m-2 d-flex justify-content-end"}>
            <Pagination
              initialPage={paginaInicial}
              totalRecords={count}
              pageLimit={medida}
              pageNeighbours={2}
              onPageChanged={handlePaginateClick}
            />
          </div>
        </Card>
      </>
    );
  }
};

export default ObrasList;
