import * as _ from "lodash";

export const createComments = async ({ date, text, offer }) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;

  const user =
    !_.isNull(localStorage.getItem("jdo-userData")) &&
    JSON.parse(localStorage.getItem("jdo-userData")).id;

  const comment = {
    fecha: date,
    texto: text,
    oferta: offer,
    create_by: user,
  };

  try {
    const res = await fetch(`${API_URL}/comentarios`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comment),
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const updateComments = async ({ id, date, text, offer }) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;

  const comment = {
    fecha: date,
    texto: text,
    oferta: offer,
  };

  try {
    const res = await fetch(`${API_URL}/comentarios/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comment),
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const markAsReadComments = async ({ id, reads }) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;

  const user =
    !_.isNull(localStorage.getItem("jdo-userData")) &&
    JSON.parse(localStorage.getItem("jdo-userData")).id;

  const comment = {
    reads: reads,
  };

  try {
    const res = await fetch(`${API_URL}/comentarios/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comment),
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const deleteComments = async (id) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;

  try {
    const res = await fetch(`${API_URL}/comentarios/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const getListComments = async (offer) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(`${API_URL}/comentarios/?oferta=${offer}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};
