import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Row, Col, Container} from 'react-bootstrap';

import ForgotForm from './components/ForgotForm';


const Forgot = () => {
  const { t } = useTranslation();

  const handelForgot = async (data) => {
    const URL = process.env.REACT_APP_API_URL;

    try {
      const res = await fetch(
        `${URL}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
        }
      );


      if (!res.ok) {
        toast.error(`${t('login_error')}`);
      } else {
        toast.success(`${t('check_email')}`);
      }
    } catch (err) {
      toast.error(`${t('login_error')}`);
    }
  };

  return (

      <section className={"buscador add-footer"}>
        <Container>
          <Row className={"mt-5"}>
            <Col xs={"12"} md={{span: 4, offset: 4}}>
              <div className="card mb-3">
                <div className="card-body">
                  <h2 className="card-title text-center logo mb-3">wikiobra.com</h2>
                  <p className="card-text">
                    <h5 className={"text-center"}>Recuperar password</h5>
                    <ForgotForm doForgot={handelForgot} />
                    <hr />
                    <div className={"text-center mt-3"}>
                      <Link to="/iniciar-sesion">
                        Volver
                      </Link>
                    </div>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
  );
};

export default Forgot;
