import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getToken } from "../logic/logic";
import { useParams } from "react-router-dom";

import { Card } from "react-bootstrap";
import { Box, Flex } from "reflexbox";
import { Nombre } from "../common/StyledComponents";

import MisProveedoresListItem from "../components/misproveedores/MisProveedoresListItem";

const ObrasProveedoresEscogidosList = ({ final }) => {
  const { t} = useTranslation();
  const params = useParams();
  const [modal, setModal] = React.useState(false);
  const [datos, setDatos] = useState([]);
  const [nombre, setNombre] = useState("");

  useEffect(() => {
    getData("");
  }, []);

  const getData = async (search, page, size) => {
    try {
      getToken();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/obras/${params.id}/proveedores?_limit=-1`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
      setDatos(data);
    } catch (err) {
      console.error("err :>> ", err);
    }

    try {
      const ores = await fetch(
        `${process.env.REACT_APP_API_URL}/obras/${params.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (ores.status !== 200) {
        console.error("Bad request");
      }
      const odata = await ores.json();
      setNombre(odata.titulo);
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  // ************************** FUNCTIONS
  const toggle = () => setModal(!modal);

  if (!datos) {
    return <div>Loading...</div>;
  }

  if (datos) {
    return (
      <>
        <Box style={{ marginTop: "10px" }}>
          <div className="container">
            <Flex>
              <Box flex="1">
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "36px",
                    textTransform: "uppercase",
                  }}
                >
                  {nombre}
                </div>
                <Nombre>Lista de proveedores de obra</Nombre>
              </Box>
            </Flex>

            <div className="containers">
              <Flex>
                <Box mr={2} mb={2} bg="#eee" p={2} flex="4">
                  <div style={{ width: "100%", textAlign: "left" }}>
                    {t("proveedor")}
                  </div>
                </Box>
                <Box mr={2} mb={2} bg="#eee" p={2} flex="2">
                  <div style={{ width: "100%", textAlign: "left" }}>
                    {t("obra")}
                  </div>
                </Box>
                <Box mr={2} mb={2} bg="#eee" p={2} flex="2">
                  <div style={{ width: "100%", textAlign: "left" }}>
                    {t("categoria")}
                  </div>
                </Box>
                <Box mr={2} mb={2} bg="#eee" p={2} flex="1">
                  <div style={{ width: "100%", textAlign: "left" }}>
                    {t("valoracion")}
                  </div>
                </Box>
                <Box mr={2} mb={2} p={2} flex="1">
                  <div style={{ width: "100%", textAlign: "left" }}>&nbsp;</div>
                </Box>
              </Flex>
            </div>

            <Card variant="sombra">
              {datos.map((d, index) => (
                <MisProveedoresListItem
                  key={index}
                  item={d}
                  final={final}
                  withAdd={false}
                />
              ))}
            </Card>
          </div>
        </Box>
      </>
    );
  }
};

export default ObrasProveedoresEscogidosList;
