import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import {
  Etiqueta,
  MainTitle,
  Nombre,
  Valor,
} from "../../common/StyledComponents";
import DelegacionesFullEditForm from "./DelegacionesFullEditForm";

const DelegacionesFullEdit = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [data, setData] = useState(null);
  const [proveedorData, setProveedorData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { id, proveedor } = params;

    const getData = async (id) => {
      setIsLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/delegacions/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        const data = await res.json();
        setData(data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error(`${t("error")}`);
      } finally {
        setIsLoading(false);
      }
    };

    getProveedor( proveedor );

    if (id) {
      getData(id);
    } else {
      setData({});
    }
  }, []);

  const refreshPage = () => {
    setTimeout(()=>{
      window.location.reload();
    }, 1000);
  }

  const getProveedor = async (proveedor_id) => {
    if (proveedor_id === null || proveedor_id === 'null' || /[a-zA-Z]/g.test(proveedor_id)) {
      return;
    }
    try {
      //
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/proveedors/${proveedor_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
      setProveedorData(data);
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  const handleSave = async (newData) => {
    const { id } = newData;
    let includeString = false;
    if ('string' === typeof id && id.includes('dt')) {
      includeString = true;
    }
    if (id && !includeString) {
      // update
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/delegacions/${id}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        await res.json();
        toast.success("Delegación guardada.");
        refreshPage();
      } catch (err) {
        toast.error(`${t("error")}`);
      }
    } else {
      // create
      if ((typeof newData?.proveedor === 'string'
        && newData?.proveedor !== undefined
        && newData?.proveedor !== null
        && newData?.proveedor?.includes('dt')) || /[a-zA-Z]/g.test(newData?.proveedor)) {
        let delegationsTemp = JSON.parse(localStorage.getItem('delegationsTemp'));
        if (delegationsTemp === null) {
          delegationsTemp = [];
        }
        localStorage.setItem('delegationsTemp', JSON.stringify([ ...delegationsTemp, newData ]));
        return;
      }
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/delegacions`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        await res.json();
        toast.success("Delegación guardada.");
      } catch (err) {
        toast.error("Error al crear el proveedor. El proveedor ya existe.");
      }
    }
  };

  if (isLoading && data === null) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="item-list">
          <div className={"row"}>
            <div className={"col col-12"}>
              <Nombre>{proveedorData?.nombre}</Nombre>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md">
              <Valor>{proveedorData?.contacto || "-"}</Valor>
              <Etiqueta>{t("Contacto")} </Etiqueta>
            </div>
            <div className="col-12 col-md">
              <Valor>{proveedorData?.email || "-"}</Valor>
              <Etiqueta>{t("Email")}</Etiqueta>
            </div>
            <div className="col-12 col-md">
              <Valor>{proveedorData?.provincia || "-"}</Valor>
              <Etiqueta>{t("provincia")} </Etiqueta>
            </div>
          </div>
        </div>
        <Row>
          <Col xs={"12"}>
            <div className={"card p-3 mt-2"}>
              <div className={"card-title"}>
                <div className={"d-flex justify-content-between"}>
                  <MainTitle>Nueva delegación</MainTitle>
                </div>
              </div>
              <div className={"card-body pt-0"}>
                <DelegacionesFullEditForm
                  proveedor={proveedorData}
                  item={data}
                  doSave={handleSave}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};

export default DelegacionesFullEdit;
