import logic, { URL } from "../../logic/logic";
import { LOGIN_QUERY, REGISTER_QUERY } from "../../logic/queryStrings";

import { toast } from "react-toastify";

// import { validateTokenEInforma } from "../../logic/einforma";
/**
   * @param {object} loginDto {email, password}

   * @returns {Promise} Fetch to API
   * @throws {error}
   */
export const login = async (userData) => {
  const { email, password } = userData;

  try {
    const res = await fetch(`${URL}${LOGIN_QUERY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        identifier: email,
        password,
      }),
    });

    const json = await res.json();

    const token = json && json.jwt;

    try {
      const oldToken = localStorage.setItem("jdo-userToken");

      if (oldToken && oldToken.length > 10) {
        toast.error(`Ya tienes una sesion iniciada`);
        return true;
      }
    } catch (e) {
      console.error("error: " + e);
    }

    if (token) {
      localStorage.setItem("jdo-userToken", token);
      localStorage.setItem("jdo-userData", JSON.stringify(json.user));
      return true;
    } else {
      console.error("User not authorized to login");
    }
  } catch (error) {
    console.error("User not authorized to login");
  }
};

export const logout = () => {
  localStorage.removeItem("jdo-userData");
  localStorage.removeItem("jdo-userToken");
};

/**
 * @param {string} newNetworkName to change to
 * @param {Object} network network to change name to
 *
 * @throws {Error} if NewNetworkName is non-string or empty
 * @throws {Error} if network is a non-object
 * @throws {Error} if network id inside object is a non-integer
 */
export const forgotPassword = (forgotData) => {
  const { email } = forgotData;

  const method = "POST";
  const body = {
    email,
  };
  return logic._call(`auth/forgot-password`, method, body);
};

export const updatePassword = (updateDate) => {
  const { code, password, passwordConfirmation } = updateDate;

  const method = "POST";
  const body = {
    user: {
      code,
      passwordConfirmation,
      password,
    },
  };
  return logic._call(`/auth/reset-password`, method, body);
};

export const register = async (userData) => {
  try {
    const res = await fetch(`${URL}${REGISTER_QUERY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(userData),
    });

    if (res.status !== 201) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Bad request");
  }
};

export const sendEmailConfirmation = async (userData) => {
  const body = {
    email: userData.email,
  };
  try {
    const res = await fetch(`${URL}/auth/send-email-confirmation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(body),
    });

    if (res.status !== 200) {
      console.error("Bad request");
    }
    return "Ok";
  } catch (error) {
    console.error("Bad request");
  }
};
