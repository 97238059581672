import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";

import { getListComments } from "../../services/comments.service";
import FullProviderCommentsForm from "./FullProviderCommentsForm";
import {
  createComments,
  updateComments,
  deleteComments,
} from "../../services/comments.service";
import { Button as CustomBtn } from "../index";

const FullProviderComments = ({ offer }) => {
  const { t } = useTranslation();
  const [comments, setComments] = useState([]);
  const [showFormComments, setShowFormComments] = useState(false);
  const [item, setItem] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleGetListComment = async () => {
    setIsLoading(true);
    const response = await getListComments(offer);
    setComments(response);
    setIsLoading(false);
  };

  const handleSave = async (data) => {
    if (data?.id) {
      await handleUpdateComment(data);
    } else {
      await handleSaveComment(data);
    }
  };

  const handleSaveComment = async (data) => {
    setIsLoading(true);
    const { date, message } = data;
    const response = await createComments({ date, text: message, offer })
      .then((data) => data)
      .catch((error) => {
        toast.error(`${t("error")}`);
      });
    setIsLoading(false);
    setItem(null);
  };

  const handleUpdateComment = async (data) => {
    setIsLoading(true);
    const { id, date, message, ofert } = data;
    const response = await updateComments({ id, date, text: message, offer })
      .then((data) => data)
      .catch((error) => {
        toast.error(`${t("error")}`);
      });
    setIsLoading(false);
    setItem(null);
  };

  const handleDeleteComment = async (id) => {
    setIsLoading(true);
    const response = await deleteComments(id)
      .then((data) => data)
      .catch((error) => {
        toast.error(`${t("error")}`);
      });
    setIsLoading(false);
    setItem(null);
  };

  useEffect(() => {
    if (!isLoading) {
      handleGetListComment();
    }
  }, [comments]);

  const handleEditComment = (comment) => {
    setShowFormComments(true);
    const { id, fecha, texto, oferta } = comment;
    const newComment = { id, date: fecha, message: texto, offer: oferta };
    setItem(newComment);
    handleGetListComment();
  };

  const handleNewComment = (flat) => {
    setShowFormComments(flat);
    const newComment = {
      id: null,
      date: new Date().toISOString().slice(0, 10),
      message: "",
      offer: null,
    };
    setItem(newComment);
    handleGetListComment();
  };

  const handleDelete = async (comment) => {
    const { id } = comment;
    await handleDeleteComment(id);
    await handleGetListComment();
  };

  return (
    <>
      <Row
        className="comment-background"
        style={{
          fontSize: "0.7rem",
          width: "100%",
          padding: "0",
          margin: "8px 0",
        }}
      >
        <Col
          xxl={"2"}
          xl={"2"}
          lg={"2"}
          md={"2"}
          sm={"2"}
          xs={"2"}
          className="text-active font-weight-bold"
          style={{ padding: "4px" }}
        >
          Fecha
        </Col>
        <Col
          xxl={"9"}
          xl={"9"}
          lg={"9"}
          md={"9"}
          sm={"9"}
          xs={"9"}
          className="text-active font-weight-bold"
          style={{ paddingLeft: "57px" }}
        >
          Comentarios
        </Col>
        <Col
          xxl={"1"}
          xl={"1"}
          lg={"1"}
          md={"1"}
          sm={"1"}
          xs={"1"}
          className="padding-0"
        >
          <Button
            variant="secondary"
            className="radius-100 padding-button move-right-05 position-relative"
            type="button"
            onClick={() => handleNewComment(true)}
          >
            +
          </Button>
        </Col>
      </Row>
      <Row style={{ width: "100%", margin: "0" }}>
        {comments &&
          comments.map((c, i) => (
            <Row
              key={`${c.id}-comment-${i}`}
              className={(i + 1) % 2 === 0 ? `field-white` : `field-gray`}
              style={{ fontSize: "0.7rem", margin: "2px 0", padding: "0" }}
            >
              <Col
                xxl={"3"}
                xl={"3"}
                lg={"3"}
                md={"3"}
                sm={"3"}
                xs={"3"}
                style={{ padding: "4px" }}
              >
                {c.fecha}
              </Col>
              <Col
                xxl={"5"}
                xl={"6"}
                lg={"5"}
                md={"5"}
                sm={"5"}
                xs={"5"}
                style={{ padding: "4px 4px 4px 24px" }}
              >
                {c.texto}
              </Col>
              <Col
                xxl={"2"}
                xl={"2"}
                lg={"2"}
                md={"2"}
                sm={"2"}
                xs={"2"}
                style={{ paddingLeft: "0" }}
              >
                <Row className="display-flex justify-content-center">
                  <Col
                    xxl={"6"}
                    xl={"6"}
                    lg={"6"}
                    md={"6"}
                    sm={"6"}
                    xs={"6"}
                    style={{ paddingLeft: "0" }}
                  >
                    <CustomBtn
                      text=""
                      className="btn-outline-success padding-0"
                      type="button"
                      icon={{ prefix: "fas", iconName: "edit" }}
                      mainClass="padding-0"
                      onClick={() => handleEditComment(c)}
                    />
                  </Col>
                  <Col xxl={"6"} xl={"6"} lg={"6"} md={"6"} sm={"6"} xs={"6"}>
                    <CustomBtn
                      text=""
                      className="color-red btn-outline-danger padding-0 margin-0"
                      type="button"
                      icon={{ prefix: "fas", iconName: "trash" }}
                      mainClass="padding-0 margin-0"
                      onClick={() => handleDelete(c)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
      </Row>
      <Row style={{ width: "100%", margin: "0" }}>
        {showFormComments && (
          <FullProviderCommentsForm
            handleShowFormComments={handleNewComment}
            handleSave={handleSave}
            handleGetListComment={handleGetListComment}
            item={item}
          />
        )}
      </Row>
    </>
  );
};

export default FullProviderComments;
