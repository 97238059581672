import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "rebass/styled-components";
import { Card } from "react-bootstrap";
import { Box, Flex } from "reflexbox";
import * as _ from "lodash";
import { getToken } from "../../logic/logic";

// import FullDetails from "./FullDetails";
import { useHistory } from "react-router-dom";
import useAppContext from "../../data/store";
import DeleteModal from "../../common/DeleteModal";
import Valoracion from "../../common/Valoracion";
import Select from "react-select";
import { Label } from "@rebass/forms";
import { toast } from "react-toastify";

const MisProveedoresListItem = ({ item, isActive, withAdd = true }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [{ user }] = useAppContext();
  const [isAdmin, setIsAdmin] = useState(false);
  const [datos, setDatos] = useState([]);
  const [ejecucion, setEjecucion] = useState([]);

  useEffect(() => {
    const role =
      !_.isNull(localStorage.getItem("jdo-userData")) &&
      JSON.parse(localStorage.getItem("jdo-userData")).role.name;
    const isAdmin = (role && role === "Administrador") || user.isAdmin;
    setIsAdmin(isAdmin);
    // recupera la lista de obras del user
    getData("");
  }, []);

  const getData = async (search) => {
    try {
      const busca = search ? search : "";

      getToken();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/obras/search?fecha_final_null=true`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ busca }),
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
      setDatos(data.items);
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  const handleClose = () => setShow(false);

  const handleConfirm = () => {
    setShow(false);
  };

  useEffect(() => {
    const opciones = datos.map((d) => ({ label: d.titulo, value: d.id }));
    setEjecucion(opciones);
  }, [datos]);

  const handleToObra = async (obra, proveedor, categoria) => {
    try {
      getToken();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/proveedors/${proveedor.id}/obra/${obra.value}/categoria/${categoria.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
      toast.success(`${t("added_ok")}`);
    } catch (error) {
      toast.error(`${t("added_error")}`);
    }
  };

  return (
    <>
      <Card>
        <div className="containers">
          <Flex mb={3} justifyContent="space-between" alignItems="flex-end">
            <Box mr={4} flex="4">
              <Valor>{item.proveedor?.nombre || "-"}</Valor>
            </Box>
            <Box mr={4} flex="2">
              <Valor>{item.obra?.titulo || "-"}</Valor>
            </Box>
            <Box mr={4} flex="2">
              <Valor>
                {item.capitulo?.categoria?.nombre ||
                  item.categoria?.nombre ||
                  "-"}
              </Valor>
            </Box>
            <Box mr={4} flex="1">
              <Valoracion oferta={item.oferta} />
            </Box>
            {withAdd && (
              <Box mr={4} flex="2">
                <Label m="1">{t("a_obra")}</Label>
                <Select
                  name="ejecucion"
                  label={t("a_obra")}
                  options={ejecucion}
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(option) =>
                    handleToObra(
                      option,
                      item.proveedor,
                      item.capitulo.categoria
                    )
                  }
                />
              </Box>
            )}
            {!withAdd && (
              <Box>
                <Button
                  m="1"
                  onClick={() =>
                    history.push(
                      `/obras/${item.obra && item.obra.id}/capitulos/${
                        item.capitulo && item.capitulo.id
                      }/ofertas/${item.oferta && item.oferta.id}/valorar/${
                        (item.oferta && item.oferta.valoracion?.id) || ""
                      }`
                    )
                  }
                >
                  {t("valorar")}
                </Button>
              </Box>
            )}
          </Flex>
        </div>
      </Card>

      <DeleteModal
        show={show}
        body={`${t("estas_seguro_de_borrar")} ${t("proveedor")}`}
        doClose={handleClose}
        doConfirm={handleConfirm}
      />
    </>
  );
};

const Etiqueta = styled.div`
  font-size: 10px;
  padding: 0px;
  color:  ${(props) => props.theme.colors.grey};
  margin:0px
  font-weight: 200 !important;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Valor = styled.div`
  font-size: 16px;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.palegrey}`};
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-weight: 300 !important;
  color: black;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Nombre = styled.div`
  font-size: 24px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.primary};
  width: 100%;
  padding: 10px 0px;
`;

export default MisProveedoresListItem;
