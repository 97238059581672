import React from "react";
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { getStatusWork } from "../components/obras/status.work.service.js";
import { getToken } from "../logic/logic";

export class MapContainer extends React.Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    data: [],
    processData: [],
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  getData = async () => {
    try {
      getToken();

      const status = await getStatusWork();

      let statusId = 0;
      const state = status.filter( ( element ) => element.type === 'ejecutada' )[0];
      statusId = state.id;


      const res = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/obras/search?estado_obra=${statusId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
      this.getLatLong()
      this.data = data?.items?.map((d) => ({...d, process: true}))
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  getLatLong = async () => {
    try {
      const res = await fetch(
        `https://plus.codes/api?address=CCQCW%2B2F Gafsa&ekey=AIzaSyCfVxysfBgiHKR9gBcREnZAkx_0X983Ui0`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  constructor() {
    super();
    this.getData();

  }

  render() {
    return (
      <Map
        google={this.props.google}
        style={style}
        initialCenter={{
          lat: 37.778519,
          lng: -122.405640
        }}
        zoom={15}
        >
          <Marker
            title={'The marker`s title will appear as a tooltip.'}
            name={'SOMA'}
            onClick={this.onMarkerClick}
            position={{lat: 37.778519, lng: -122.405640}} />
          <Marker
            title={'The marker`s title will appear as a tooltip.'}
            name={'SOMA 1'}
            onClick={this.onMarkerClick}
            position={{lat: 36.778519, lng: -121.405640}} />
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
              <div>
                <h1>{this.state.selectedPlace.name}</h1>
              </div>
          </InfoWindow>
      </Map>
    );
  }
}

// const Maps = () => {
//   const { t } = useTranslation();
//   return (
//     <Container>
//       <Row className={"justify-content-center"}>
//         <Col xxl={"10"} xl={"10"} lg={"10"} md={"11"} sm={"10"} xs={"11"}>
//           <div className={"card p-3"}>
//             MAPS
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCfVxysfBgiHKR9gBcREnZAkx_0X983Ui0'
})(MapContainer);

const style = {
  width: '100%',
  height: '100%'
}

// export default Maps;

