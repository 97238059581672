import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Flex, Box } from "reflexbox";
import * as _ from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const EdicionPatrocinadores = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const logged = !_.isNull(localStorage.getItem('jdo-userToken'));
    setIsLogged(logged);

    // retrieve sponsors images
    getData();
  }, []);

  const getData = async (search, page, size) => {
    const token = localStorage.getItem('jdo-userToken');
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/patrocinadors`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (res.status !== 200) {
        console.error('Bad request');
      }
      const data = await res.json();
      setImages(data);
    } catch (err) {
      console.error('err :>> ', err);
    }
  };

  const schema = Yup.object().shape({
    logo: Yup.mixed().required(),
    url: Yup.mixed().required(),
  });

  const handleSubmit = async (values) => {

    // primero borrar la imagen existente
    try {
      const obj = {
        url: values.url
      }
      let form = new FormData();
      form.append('files.logo', values.logo);
      form.append('data', JSON.stringify(obj));

      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/patrocinadors/${values.index}`, {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${localStorage.getItem('jdo-userToken')}`,
          }),
          body: form,
        });
        if (res.status !== 200) {
          console.error('Bad request');
        }
        // recargar las imagenes
        getData();
      } catch (err) {
        toast.error('Error al enviar el logotipo.');
      }
    } catch (err) {
      toast.error('Error al enviar el logotipo.');
    }
  };

  if (images.length === 0) {
    return <Box>Cargando...</Box>;
  }

  if (images.length > 0) {
    return (
      <Flex
        justifyContent='center'
        enableReinitialize={true}
        alignItems='center'
        flexWrap='wrap'
        style={{ maxWidth: '1536px', margin: 'auto' }}
      >
        <Box width={['50%']} mt={4}>
          <Flex
            flexDirection='column'
            justifyContent='space-around'
            alignItems='center'
          >
            {images.map((image) => {
              return (
                <Box key={image.id} mt={5}>
                  <Formik
                    initialValues={{ url: image?.url || "", logo: image?.logo || null, index: image.id }}
                    onSubmit={handleSubmit}
                    validationSchema={schema}
                    render={({
                      values,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          {/* <pre>{JSON.stringify(image, null, 4)}</pre> */}
                          <div className='form-group'>
                            <input type='hidden' name='index' />
                            <Flex flexDirection='column'>
                              <label htmlFor='url'>Enlace:</label>
                              <input
                                type='text'
                                name='url'
                                value={values.url}
                                onChange={handleChange}
                              />
                            </Flex>
                            <input
                              style={{
                                padding: '8px',
                                backgroundColor: 'transparent',
                                border: 'none',
                              }}
                              id='file'
                              name='file'
                              type='file'
                              onChange={(event) => {
                                setFieldValue(
                                  'logo',
                                  event.currentTarget.files[0]
                                );
                              }}
                              className='form-control'
                            />
                            <Box mt={3}>
                              <img src={image.logo?.url} width='120px' />
                            </Box>
                          </div>
                          <button
                            type='submit'
                            className='btn btn-primary btn-block'
                          >
                            Enviar
                          </button>
                        </form>
                      );
                    }}
                  />
                </Box>
              );
            })}
          </Flex>
        </Box>
      </Flex>
    );
  }
};

export default EdicionPatrocinadores;
