export const createParticipants = async ({ provider, category, work }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("jdo-userToken");
  const obj = {
    proveedor: provider,
    categoria: category,
    obra: work,
  };
  try {
    const res = await fetch(`${API_URL}/participantes`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    await res.json();
  } catch (err) {
    throw err;
  }
};

export const getParticipantsByProvider = async ({ providerID }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("jdo-userToken");
  try {
    const res = await fetch(
      `${API_URL}/participantes/?proveedor=${providerID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    throw err;
  }
};

export const deleteParticipantsByID = async ({ participantsID }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("jdo-userToken");
  try {
    const res = await fetch(`${API_URL}/participantes/${participantsID}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    throw err;
  }
};
