export const getWorkById = async (id) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(`${API_URL}/obras/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};

export const getDetailsWorkById = async (id) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(`${API_URL}/obras/${id}/detalles`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (res.status !== 200) {
      console.error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("err :>> ", err);
  }
};
