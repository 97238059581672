const axios = require("axios");

export const authLinkedIn = async (code) => {
  const handleProcessData = (data) => {
    const email = data?.data?.mail?.elements[0]["handle~"].emailAddress;
    const fullName = `${data?.data?.personales?.localizedFirstName} ${data?.data?.personales?.localizedLastName}`;
    const linkedin = `${data?.data?.personales?.vanityName}`;
    return {
      email,
      fullName,
      linkedin,
    };
  };

  const response = await axios
    .post(`${process.env.REACT_APP_API_URL}/users/linkedin`, {
      method: "POST",
      token: code,
    })
    .then((response) => handleProcessData(response.data))
    .catch((err) => {
      console.error("Error getting LinkedIn access token");
    });

  return response;
};
