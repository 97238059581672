const setEInfomaToken = async () => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_EINFORMA}/oauth/token`,
      {
        method: "POST",
        body:
          "grant_type=client_credentials&client_id=" +
          process.env.REACT_APP_EINFORMA_CLIENT_ID +
          "&client_secret=" +
          process.env.REACT_APP_EINFORMA_CLIENT_SECRET +
          "&grant_type=client_credentials" +
          "&scope=buscar%3Aconsultar%3Aempresas",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    const json = await res.json();

    if (res.status === 200) {
      return { token: json.access_token, expiresIn: json.expires_in };
    }
  } catch (error) {
    console.error("User not authorized to login");
  }
};

async function createNewEInformaToken() {
  const { token, expiresIn } = await setEInfomaToken();
  const tokenExpirationDate = Date.now() + expiresIn;
  sessionStorage.setItem("jdo-einformatoken", token);
  sessionStorage.setItem(
    "jdo-einformatokenExpirationDate",
    tokenExpirationDate
  );
  return token;
}

export async function getEInformaToken() {
  //first check if there is a valid token
  const token = sessionStorage.getItem("jdo-einformatoken");
  const tokenExpirationDate = sessionStorage.getItem(
    "jdo-einformatokenExpirationDate"
  );

  if (token === "undefined") {
    // no token stored, create a new one and return it
    return await createNewEInformaToken();
  }

  // token found, check if it is expired
  if (Date.now() > tokenExpirationDate) {
    // token is expired, create a new one and return it
    return await createNewEInformaToken();
  }
  // saved token is still valid, return it
  return token;
}
