import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { Box, Flex } from "reflexbox";
import { toast } from "react-toastify";
import useAppContext from "../../../data/store";
import Pagination from "../../../common/Pagination";
import { Button, Select, Input } from "../../../components";
import { getListAllCategoriesForSelect, getProvincias } from "../../../data/api/common";
import { SIZE_PAGE } from "../../../logic/queryStrings";
import ValoracionScore from "../../../common/ValoracionScore";
import DeleteModal from "../../../common/DeleteModal";
import { getEInformaToken } from "../../../logic/einforma";

const Buscador = ({ loggin, setClass = () => {} }) => {
  const [{ user }] = useAppContext();
  const [provincias, setProvincias] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [datos, setDatos] = useState([]);
  const [datoseInforma, setDatosInforma] = useState([]);
  const [total, setTotal] = useState(null);
  const [classTable, setClassTable] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState(null);
  const [isLogin, setIsLogin] = useState(null);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const { register, handleSubmit, control, reset, getValues, setValue } =
    useForm();
  const { t } = useTranslation();
  const provinciaRef = useRef();
  const categoriaRef = useRef();
  const btnSearchRef = useRef();

  useEffect(() => {
    const role =
      !_.isNull(localStorage.getItem("jdo-userData")) &&
      JSON.parse(localStorage.getItem("jdo-userData")).role.name;
    const isAdmin = (role && role === "Administrador") || user.isAdmin;
    setIsLogin(role);
    setRole(isAdmin);
    let isMounted = true;
    handleLoadProvinces();
    handleLoadCategories();
    return () => {
      isMounted = false;
    }
  }, []);

  const handleLoadProvinces = async () => {
    const provinciasList = await getProvincias();
    setProvincias(provinciasList[0]);
    handleLoadCategories();
  }

  const handleLoadCategories = async () => {
    let limit = 20;
    let start = 0;
    let finish = false;
    let listCategories = [];
    setIsLoading(true);
    do {
      let response = [];
      response = await getListAllCategoriesForSelect({ limit, start });
      if (response?.length === 0) {
        finish = true;
      }
      start += 20;
      if (!finish) {
        response?.forEach((p) => {
          listCategories?.push(p);
        });
      }
      const mergeCategories = [...categorias, ...listCategories];
      setCategorias(mergeCategories);
    } while (!finish);
    setIsLoading(false);
  };

  const getEInformaData = async ({ empresa, cif }) => {
    const getFromEmpresa = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_API_EINFORMA}/companies?companySearch=${empresa}&rows=20`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${await getEInformaToken()}`,
          },
        }
      );
      const data = await res.json();
      setDatosInforma(data.empresa);
    };

    const getFromCif = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_API_EINFORMA}/companies/${cif}/report`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${await getEInformaToken()}`,
          },
        }
      );

      const data = await res.json();
      setDatosInforma([data]);
    };

    try {
      if (empresa && !cif) {
        getFromEmpresa();
      }

      if (cif && !empresa) {
        getFromCif();
      }

      if (empresa && cif) {
        getFromEmpresa();
      }
    } catch (error) {
    }
  };

  const getData = async ({
    type,
    empresa,
    cif,
    provincia,
    categoria,
    tag,
    page = 0,
    size = SIZE_PAGE,
  }) => {
    const filters = [false, false, false, false, false]

    if (empresa.length >= 1) {
      filters[0] = true;
    }

    if (cif.length >= 1) {
      filters[1] = true;
    }

    if (provincia !== undefined && provincia?.length >= 1) {
      filters[2] = true;
    }

    if (categoria !== undefined && categoria?.length >= 1) {
      filters[3] = true;
    }

    if (tag.length >= 1) {
      filters[4] = true;
    }

    if (!filters.includes(true)) {
      toast.error('Rellene algún campo para buscar...');
      return;
    }

    try {
      setLoading(true);
      const start = page * size;
      const token = localStorage.getItem("jdo-userToken");
      let headers =  {
        "Content-Type": "application/json",
      };

      if (token !== undefined && token !== null) {
        headers =  {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/proveedors/search?_start=${start}&_limit=${size}`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            busca: empresa,
            provincia,
            categoria: categoria && categoria.length > 0 ? categoria : null,
            cif,
            tag,
            filtros: {
              subcontratista: !!type.find((i) => i === "subcontratista"),
              distribuidor: !!type.find((i) => i === "distribuidor"),
              fabricante: !!type.find((i) => i === "fabricante"),
              delegacions: !!type.find((i) => i === "delegacions"),
              calificado: !!type.find((i) => i === "calificado"),
              conweb: !!type.find((i) => i === "conweb"),
            },
            _start: start,
            _limit: size,
          }),
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
      setDatos(data.items);
      setTotal(data.total);
      setClassTable("con-tabla");
      setClass("con-tabla");
      return data;
    } catch (err) {
      console.error("err :>> ", err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async ({
    type,
    empresa,
    cif,
    provincia,
    categoria,
    tag,
  }) => {
    await getData({
      type,
      empresa,
      cif,
      provincia,
      categoria,
      tag,
    });

    isLogin && getEInformaData({ empresa, cif });
    getValues();
  };

  const handlePageChanged = async ({ currentPage }) => {
    const { type, empresa, cif, provincia, categoria, tag } = getValues();

    await getData({
      type,
      empresa,
      cif,
      provincia,
      categoria,
      tag,
      page: currentPage,
    });
  };

  const handleClose = () => setShow(false);

  const handleConfirm = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/proveedors/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      setId(null);
      getData();
      toast.success(t("delete_ok"));
    } catch (err) {
      toast.error(`${t("error")}`);
    }

    setShow(false);
  };

  const handleDelete = (id) => {
    setId(id);
    setShow(true);
  };

  const checkKeyDown = (e) => {
    const keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 13) {
      e.target.blur();
      if (btnSearchRef.current) {
        btnSearchRef.current.click();
      }
    }
  };

  const redirectDetail = (id) =>
    isLogin ? window.open(`/full/${role ? "edit" : "details"}/${id}`) : window.open(`/full/details/${id}`); // isLogin && history.push(`/full/${role ? "edit" : "details"}/${id}`);
  let className = '';
  if (!loggin) {
    className = 'middle-search';
  }
  return (
    <>
      <section
        className={`buscador ${classTable} ${loggin === true && "false"}`}
      >
        <Container>
          <Row>
            <Col xs={12} className="content-search align-middle">
              {/* <div className={"middle-search"}> */}
              <div className={className}>
                <div className="card p-3">
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      onKeyDown={(e) => checkKeyDown(e)}
                    >
                      <h2
                        className={
                          "card-title" + (loggin === true && " visually-hidden")
                        }
                      >
                        Buscador de empresas subcontratistas y proveedores de
                        construcción.
                      </h2>
                      <div className="">
                        <h5 className={"my-3"}>Tipo de empresa</h5>
                        <div className="mx-3">
                          <div className="form-check form-check-inline">
                            <Input
                              type={"checkbox"}
                              name={"type"}
                              value={"subcontratista"}
                              register={register}
                              checked
                            />
                            <label
                              className="form-check-label mx-1"
                              htmlFor="inlineCheckbox1"
                            >
                              Subcontratista
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              type={"checkbox"}
                              name={"type"}
                              value={"distribuidor"}
                              register={register}
                              checked
                            />
                            <label
                              className="form-check-label mx-1"
                              htmlFor="inlineCheckbox2"
                            >
                              Distribuidor
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              type={"checkbox"}
                              name={"type"}
                              value={"fabricante"}
                              register={register}
                              checked
                            />
                            <label
                              className="form-check-label mx-1"
                              htmlFor="inlineCheckbox1"
                            >
                              Fabricante
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              type={"checkbox"}
                              name={"type"}
                              value={"delegacions"}
                              register={register}
                            />
                            <label
                              className="form-check-label mx-1"
                              htmlFor="inlineCheckbox1"
                            >
                              Delegaciones
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              type={"checkbox"}
                              name={"type"}
                              value={"calificado"}
                              register={register}
                            />
                            <label
                              className="form-check-label mx-1"
                              htmlFor="inlineCheckbox2"
                            >
                              Calificado
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              type={"checkbox"}
                              name={"type"}
                              value={"conweb"}
                              register={register}
                            />
                            <label
                              className="form-check-label mx-1"
                              htmlFor="inlineCheckbox2"
                            >
                              Con web
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row g-2 my-4">
                        <div className="col-md-4">
                          <Input
                            className="form-control"
                            placeholder="Empresa"
                            register={register}
                            name={"empresa"}
                          />
                        </div>
                        <div className="col-md-4">
                          <Input
                            className="form-control"
                            placeholder="CIF"
                            register={register}
                            name={"cif"}
                          />
                        </div>
                        <div className="col-md-4">
                          <Controller
                            name="provincia"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                placeholder={"Provincia"}
                                options={provincias}
                                isDisabled={provincias.length === 0}
                                ref={provinciaRef}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <Input
                            className="form-control"
                            placeholder="Palabra clave"
                            register={register}
                            name={"tag"}
                          />
                        </div>

                        <div className="col-md-8">
                          <Controller
                            name="categoria"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                placeholder={"Categorías"}
                                options={categorias}
                                isDisabled={categorias.length === 0}
                                isMulti
                                ref={categoriaRef}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="d-flex mt-1 justify-content-end">
                        <Button
                          text={"Limpiar"}
                          className={"btn-secondary"}
                          icon={{ prefix: "fas", iconName: "eraser" }}
                          mx={"mx-2"}
                          type="button"
                          onClick={() => {
                            reset();
                            setDatos([]);
                            setDatosInforma([]);
                            setTotal(null);
                            setClassTable("");
                            setClass("");
                            provinciaRef.current.select.clearValue();
                            categoriaRef.current.select.clearValue();
                          }}
                          loading={loading}
                          loadingText={""}
                        />
                        <Button
                          text={"Buscar"}
                          className={"btn-primary"}
                          icon={{ prefix: "fas", iconName: "search" }}
                          type={"submit"}
                          loading={loading}
                          loadingText={"Buscando"}
                          btnRef={btnSearchRef}
                        />
                      </div>
                    </form>
                    {total === 0 && datos.length === 0 ? (
                      <>
                        <Col xs={12}>
                          <b>Nombre</b> o <b>CIF</b> introducido no encontrado.
                          Introduce otros criterios de búsqueda
                        </Col>
                      </>
                    ) : total > 0 ? (
                      <>
                        <h5 className={"mt-4 mb-2"}>
                          Resultado de la busqueda
                        </h5>
                        <div className="table-responsive">
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Nombre</th>
                                <th>CIF</th>
                                <th>Tipo</th>
                                <th>Provincia</th>
                                <th>Ámbito</th>
                                <th>Web</th>
                                <th>Contacto</th>
                                <th>Teléfono</th>
                                <th>Categoría</th>
                                <th>
                                  <span className="d-flex justify-content-center align-items-center">
                                    Calificación
                                    <FontAwesomeIcon
                                      data-tip="Calificación"
                                      data-for="calification-help"
                                      icon={{
                                        prefix: "fa",
                                        iconName: "info-circle",
                                      }}
                                    />
                                  </span>

                                  <ReactTooltip
                                    id="calification-help"
                                    getContent={function () {
                                      return (
                                        <div>
                                          <Flex flexDirection="column">
                                            <Flex alignItems="center" my={1}>
                                              <Box
                                                sx={{
                                                  display: "inline-block",
                                                  color: "white",
                                                  bg: `red`,
                                                  px: 4,
                                                  py: 2,
                                                  borderRadius: 9999,
                                                  textAlign: "center",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "white",
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  DE 0,00 A 1,99
                                                </p>
                                              </Box>
                                              <Box ml={2}>MEJORABLE</Box>
                                            </Flex>

                                            <Flex alignItems="center" my={1}>
                                              <Box
                                                sx={{
                                                  display: "inline-block",
                                                  color: "white",
                                                  bg: `green`,
                                                  px: 4,
                                                  py: 2,
                                                  borderRadius: 9999,
                                                  textAlign: "center",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "white",
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  DE 2,00 A 2,50
                                                </p>
                                              </Box>
                                              <Box ml={2}>NORMAL</Box>
                                            </Flex>

                                            <Flex alignItems="center" my={1}>
                                              <Box
                                                sx={{
                                                  display: "inline-block",
                                                  color: "white",
                                                  bg: `blue`,
                                                  px: 4,
                                                  py: 2,
                                                  borderRadius: 9999,
                                                  textAlign: "center",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "white",
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  DE 2,51 A 3,00
                                                </p>
                                              </Box>
                                              <Box ml={2}>EXCELENTE</Box>
                                            </Flex>
                                          </Flex>
                                        </div>
                                      );
                                    }}
                                  />
                                </th>
                                {isLogin && role && <th> </th>}
                              </tr>
                            </thead>
                            <tbody>
                              {datos.map((d) => (
                                <tr key={d.id}>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.nombre}
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.cif}
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.tipo_proveedor &&
                                      d.tipo_proveedor.nombre}
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.provincia}
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.ambito}
                                  </td>
                                  <td>
                                    <a href={`${d.web}`} target="_blank">
                                      {d.web
                                        ? d.web[4] === "s"
                                          ? d.web.substr(8)
                                          : d.web.substr(7)
                                        : ""}
                                    </a>
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.contacto}
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    <a href={"tel:" + d.tel1}>{d.tel1}</a>
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.categorias.map((c) => (
                                      <div
                                        key={c.id}
                                        className={"category-item"}
                                      >
                                        {c.nombre ? c.nombre : "-"}
                                      </div>
                                    ))}
                                  </td>
                                  <td>
                                    <ValoracionScore
                                      score={d.score}
                                      small={true}
                                    />
                                  </td>
                                  {isLogin && role && (
                                    <td>
                                      <Button
                                        text={""}
                                        className={
                                          "btn-outline-danger btn-sm w-100 mb-2 min-47"
                                        }
                                        ç
                                        icon={{
                                          prefix: "fas",
                                          iconName: "trash",
                                        }}
                                        onClick={() => handleDelete(d.id)}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div className={"my-2 d-flex justify-content-end"}>
                          <Pagination
                            initialPage={0}
                            totalRecords={+total}
                            pageLimit={SIZE_PAGE}
                            pageNeighbours={2}
                            onPageChanged={(e) => {
                              handlePageChanged(e);
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <DeleteModal
          show={show}
          body={`${t("estas_seguro_de_borrar")} ${t("proveedor")}`}
          doClose={handleClose}
          doConfirm={handleConfirm}
        />
      </section>

      <section className={`buscador ${loggin === true && "false"}`}>
        {isLogin && datoseInforma && datoseInforma.length ? (
          <Container>
            <Row>
              <Col xs={12}>
                <div className="card p-3">
                  <div className="card-body">
                    <h5>Coincidencias con Registro Mercantil</h5>
                    <p>
                      Puedes incorporar nuevos proveedores a wikiobra si
                      ingresas un e-mail válido
                    </p>
                    <div>
                      {datoseInforma.map((datos) => (
                        <div
                          className="divider-custom"
                          onClick={() =>
                            isLogin &&
                            window.open(
                              `/full/edit/${datos.id || datos.identificativo}`
                            )
                          }
                          key={datos.id || datos.identificativo}
                        >
                          <div className="divider-custom-line"></div>
                          {datos.denominacion} -{" "}
                          {datos.provincia || datos.localidad}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : null}
      </section>
    </>
  );
};

export default Buscador;
