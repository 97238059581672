import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box, Flex } from "reflexbox";
import useAppContext from "../data/store";
import { Etiqueta, Nombre, Valor } from "../common/StyledComponents";
import ObrasOfertasListItem from "../components/obras/ObrasOfertasListItem";
import Leyenda from "../common/Leyenda";

const ObrasOfertasList = () => {
  const { t, } = useTranslation();
  const [_, dispatch] = useAppContext();
  const [datos, setDatos] = useState(null);
  const [obra, setObra] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    setObra(id);

    if (id) {
      getData(id, 0, 9999);
    }

    dispatch({
      origin: "app",
      type: "SET_CAPITULO",
      capitulo: null,
    });
    dispatch({
      origin: "app",
      type: "SET_OFERTA",
      capitulo: null,
    });
  }, []);

  const getData = async (id, page, size) => {
    try {
      const res = await fetch(
        // `${process.env.REACT_APP_API_URL}/obras/${id}/detalles`,
        `${process.env.REACT_APP_API_URL}/participantes/?obra_eq=${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();

      setDatos(data);
    } catch (err) {
      console.error("err :>> ", err);
    }
    try {
      const res = await fetch(
        // `${process.env.REACT_APP_API_URL}/obras/${id}/detalles`,
        `${process.env.REACT_APP_API_URL}/obras/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
      dispatch({
        origin: "app",
        type: "SET_OBRA",
        obra: data,
      });
      setObra(data);
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  const handleDelete = async (item) => {
    try {
      const res = await fetch(
        // `${process.env.REACT_APP_API_URL}/ofertas/${item}`,
        `${process.env.REACT_APP_API_URL}/participantes/${item}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      getData(id, 0, 9999);
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  // ************************** FUNCTIONS

  if (!datos) {
    return <div>Loading...</div>;
  }

  if (datos) {
    return (
      <div className="container">
        <div className="item-list">
          <div className={"row"}>
            <div className={"col col-12"}>
              <Nombre>{obra?.titulo}</Nombre>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md">
              <Valor>{obra?.constructora || "-"}</Valor>
              <Etiqueta>{t("constructora")} </Etiqueta>
            </div>
            <div className="col-12 col-md">
              <Valor>{obra?.comunidad || "-"}</Valor>
              <Etiqueta>{t("comunidad")}</Etiqueta>
            </div>
            <div className="col-12 col-md">
              <Valor>{obra?.provincia || "-"}</Valor>
              <Etiqueta>{t("provincia")} </Etiqueta>
            </div>
          </div>
        </div>

        <Flex>
          <Box flex="1">
            <ObrasOfertasListItem
              obra={id}
              participantes={datos}
              doDelete={handleDelete}
            />
          </Box>
        </Flex>
        <Leyenda />
      </div>
    );
  }
};

export default ObrasOfertasList;
