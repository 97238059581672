import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Form } from "react-bootstrap";
import { Button } from "../../components";

import { searchByName } from "../../services/provider.service";

import { getEInformaToken } from "../../logic/einforma";

export const SearchBusinessModal = (props) => {
  const { show, doClose, title, needle, setChangeFilter, setListProvidersNew } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [listProviders, setListProviders] = useState([]);
  const [providers, setProviders] = useState([]);
  const [datoseInforma, setDatosInforma] = useState([]);

  const datos = [];

  const handleSearchProviderWhileLoading = async (needle) => {
    let providers = null;
    providers = listProviders.filter((r) => {
      return r?.nombre?.includes(needle) ?? r;
    });
    const providersByName = await searchByName(needle);
    if (providers === null || providers === undefined || providers.length === 0) {
      providers = providersByName?.filter((r) => {
        return r?.nombre?.includes(needle) ?? r;
      });
    }
    if (providers === null || providers === undefined || providers.length === 0) {
      if (needle.length > 3) {
        setDatosInforma([]);
        await getEInformaData(needle);
      }
    }

    const list = [];
    const listP = [];
    if (providers !== null && providers !== undefined && providers.length > 0) {
      providers?.forEach((d) => list.push({...d, denominacion: d.nombre}));
      providers?.forEach((d) => listP.push(d?.nombre));
    }
    datoseInforma?.forEach((d) => list.push(d));
    datoseInforma?.forEach((d) => listP.push(d?.denominacion));
    setListProvidersNew(listP)
    setProviders(list);
    // setProviders(providers);
  };

  const getEInformaData = async (empresa) => {
    const getFromEmpresa = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_API_EINFORMA}/companies?companySearch=${empresa}&rows=20`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${await getEInformaToken()}`,
          },
        }
      );
      const data = await res.json();
      setDatosInforma(data.empresa);
    };

    try {
      getFromEmpresa();
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    handleSearchProviderWhileLoading(needle);
  }, [needle, show]);

  return (
    <Modal show={show} fullscreen="true" onHide={doClose}>
      <Modal.Header closeButton>
        {title}
      </Modal.Header>
      <Modal.Body className="modal-dialog-scrollable">
        <Container>
          <Row>
            <Col xs={12}>
              <div className={"card p-3"}>
                <div className={"card-body"}>
                  <div>
                  { providers.length > 0 ? (
                      <>
                        {providers.map((p) => (
                          <div
                            className="divider-custom"
                            onClick={() => {
                              setChangeFilter(p.nombre || p?.denominacion);
                              doClose();
                            }}
                            key={datos.id || datos.identificativo}
                          >
                            <div className="divider-custom-line"></div>
                            {p.nombre || p?.denominacion} -{" "}
                            {p?.provincia || p?.localidad}
                          </div>
                        ))}
                      </>
                    ) : ('No encontrado')}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className={"card-footer pt-0"}>
          <div className={"d-flex justify-content-around"}>
            <Button text="Cancelar" className="btn-danger" onClick={doClose} />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
