import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Collapse } from "bootstrap";
import { Check } from "react-bootstrap-icons";
import ReactTooltip from "react-tooltip";

import { Button } from "../../components";
import DeleteModal from "../../common/DeleteModal";
import FullProveedorDetails from "../full/FullProveedorDetails";
import FullProviderComments from "../full/FullProviderComments";
import FullProviderDelegations from "../full/FullProviderDelegations";
import {
  createParticipants,
  getParticipantsByProvider,
  deleteParticipantsByID,
} from "../../services/participants.service";

import * as _ from "lodash";

import {
  markAsReadComments,
} from "../../services/comments.service";

import styled from "styled-components";

import { getCountDelegationsByProviderID } from "../../services/delegation.service";
import { Box, Flex } from "rebass";
import useAppContext from "../../data/store";
import { getUserID } from "../../utils";

const ObrasProveedoresListItem = ({ item, isActive, doDelete, doUpdate }) => {
  const { t } = useTranslation();
  const [{ app }, dispatch] = useAppContext();

  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);
  const [pending, setPending] = useState(false);
  const [haveDelegations, setHaveDelegations] = useState(false);
  const [showDelegations, setShowDelegations] = useState(false);

  var [toggle, setToggle] = useState(false);

  useEffect(() => {
    var myCollapse = document.getElementById("collapseTarget" + item.id);
    var bsCollapse = new Collapse(myCollapse, { toggle: false });
    toggle ? bsCollapse.show() : bsCollapse.hide();

    if (!item?.recepcion && !item?.contratado && !item?.rechazada) {
      setStatus("pending");
    } else if (item?.recepcion && !item?.contratado  && !item?.rechazada) {
      setStatus("received");
    } else if (item?.rechazada && !item?.recepcion && !item?.contratado) {
      setStatus("rejected");
    } else if (item?.recepcion && item?.contratado  && !item?.rechazada) {
      setStatus("hired");
    }

    handleGetProviderByID(item?.proveedor?.id);
  });

  const handleDelete = () => {
    doDelete(item.id);
  };

  const handleClose = () => {
    setPending(false);
  };

  const handleRejected = () => {
    if (status !== "rejected") setStatus("rejected");
    else setStatus("pending");
    doUpdate(item.id, "rejected", item);
  };

  const handleConfirm = () => {
    if (status !== "received") setStatus("received");
    else setStatus("pending");
    doUpdate(item.id, "received", item);
  };

  const formatFecha = (date) => {
    const fecha_str = date.split("-");
    return `${fecha_str[2]}/${fecha_str[1]}/${fecha_str[0]}`;
  };

  const handleHired = async (item) => {
    const provider = item?.proveedor?.id;
    const category = item?.capitulo?.categoria;
    const work = item?.capitulo?.obra;
    const response = await getParticipantsByProvider({ providerID: provider });
    if (response.length < 1) {
      doUpdate(item.id, "hired", item);
      await createParticipants({ provider, category, work });
    } else {
      response.forEach((p) => {
        deleteParticipantsByID({ participantsID: p.id });
      });
      doUpdate(item.id, "noHired", item);
    }
  };

  const handleGetProviderByID = async (ID) => {
    const response = await getCountDelegationsByProviderID(ID)
      .then((d) => d)
      .catch((e) => console.error(e));

    if (response > 0) {
      setHaveDelegations(true);
    }
  };

  const processScore = (value) => {
    let color = "";
    if (value >= 0 && value <= 1.99) {
      color = "red";
    } else if (value >= 2 && value <= 2.5) {
      color = "green";
    } else if (value >= 2.51 && value <= 3.0) {
      color = "blue";
    } else if (value >= 3.0 && value <= 4.0) {
      color = "blue";
    }
    return <span data-tip data-for="score" style={{ color: color, fontWeight: "bold" }}>{value}</span>;
  };

  const handleCollapse = async (comments) => {
    const chapter = item.capitulo.id;
    const object = app.obra.capitulos.filter((element) => element.id === chapter)[0];
    const indexChapter = app.obra.capitulos.indexOf(object);
    const user = getUserID();

    let result = await Promise.all(comments.map(async (item) => {
      const reads = item?.reads?.map((element) => element.id) || [];

      if (!reads?.includes(user)) {
        return await markAsReadComments({ id: item?.id, reads: [...reads, user]})
          .then((data) => data)
          .catch((error) => {
            console.error(`${t("error")}`);
          });
      }
    }));

    result = result.filter((element) => element)

    const process = app;

    result.forEach((element) => {
      const offerID = element?.oferta?.id;
      const offer = object.offers.filter((element1) => element1.id === offerID)[0];
      const indexOffer = object.offers.indexOf(offer);
      const commentID = element?.id;
      const comment = object?.offers[indexOffer]?.comentarios?.filter((element1) => element1.id === commentID)[0];
      const indexComment =  object.offers[indexOffer]?.comentarios?.indexOf(comment);
      process.obra.capitulos[indexChapter].offers[indexOffer].comentarios[indexComment] = element;
    });

    dispatch({
      origin: "app",
      type: "SET_OBRA",
      obra: process.obra,
    });

  }

  return (
    <div className="accordion-item my-2">
      <h2 className="accordion-header" id="headingOne">
        <button
          className={"p-2 accordion-button " + (!toggle && "collapsed")}
          type="button"
          onClick={() => {
            setToggle((toggle) => !toggle)
            handleCollapse(item.comentarios)
          }}
        >
          <span className={
              "category-name w-100 px-2 d-flex flex-row align-items-center justify-content-between"
            }>
            <div className={"d-flex flex-column"}>
              <div>
                {item.esValorable && <Check size={24} color="green" />}
                <b>{(item.proveedor && item.proveedor.nombre) || "-"}</b>
                {" "}
                {(item && processScore(item.proveedor.score)) || "-"}
                <ReactTooltip
                  id="score"
                  getContent={function () {
                    return (
                      <div>
                        <Flex flexDirection="column">
                          <Flex alignItems="center" my={1}>
                            <Box
                              sx={{
                                display: "inline-block",
                                color: "white",
                                bg: `red`,
                                px: 4,
                                py: 2,
                                borderRadius: 9999,
                                textAlign: "center",
                              }}
                            >
                              <p
                                style={{
                                  color: "white",
                                  marginBottom: 0,
                                }}
                              >
                                DE 0,00 A 1,99
                              </p>
                            </Box>
                            <Box ml={2}>MEJORABLE</Box>
                          </Flex>

                          <Flex alignItems="center" my={1}>
                            <Box
                              sx={{
                                display: "inline-block",
                                color: "white",
                                bg: `green`,
                                px: 4,
                                py: 2,
                                borderRadius: 9999,
                                textAlign: "center",
                              }}
                            >
                              <p
                                style={{
                                  color: "white",
                                  marginBottom: 0,
                                }}
                              >
                                DE 2,00 A 2,50
                              </p>
                            </Box>
                            <Box ml={2}>NORMAL</Box>
                          </Flex>

                          <Flex alignItems="center" my={1}>
                            <Box
                              sx={{
                                display: "inline-block",
                                color: "white",
                                bg: `blue`,
                                px: 4,
                                py: 2,
                                borderRadius: 9999,
                                textAlign: "center",
                              }}
                            >
                              <p
                                style={{
                                  color: "white",
                                  marginBottom: 0,
                                }}
                              >
                                DE 2,51 A 3,00
                              </p>
                            </Box>
                            <Box ml={2}>EXCELENTE</Box>
                          </Flex>
                        </Flex>
                      </div>
                    );
                  }}
                />
              </div>
              {item.realizacion && (
                <>
                  <div>
                    <small style={{ color: "gray" }}>
                      {"Propuesta envida el: "}
                      {item.realizacion ? formatFecha(item.realizacion) : "-"}
                    </small>
                  </div>
                </>
              )}
            </div>
          </span>
        </button>

        {item.realizacion && (
          <>
            <div>
              <Button
                text={""}
                className={
                  status === "rejected"
                    ? "btn-danger mx-1bttn-sm status"
                    : "btn-outline-danger mx-1 btn-sm status"
                }
                mainClass={"btn-over-accordion rejected"}
                icon={{ prefix: "fas", iconName: "times" }}
                onClick={() => handleRejected(item.id)}
                data_for="text-red"
              />
              <ReactTooltip
                place="top"
                id="text-red"
                getContent={function () {
                  return <div>Solicitud de oferta rechazada.</div>;
                }}
              />
            </div>
            <div>
              <Button
                text={""}
                className={
                  status === "received" || status === "hired"
                    ? "btn-success mx-1 btn-sm status"
                    : "btn-outline-success mx-1 btn-sm status"
                }
                mainClass={"btn-over-accordion received"}
                icon={{ prefix: "fas", iconName: "check" }}
                onClick={() => handleConfirm(item.id)}
                data_for="text-green"
              />

              <ReactTooltip
                place="top"
                id="text-green"
                getContent={function () {
                  return <div>Oferta recibida</div>;
                }}
              />
            </div>
          </>
        )}
        {item?.realizacion && (
          <div>
            <small style={{ color: "gray" }} className="text-success-hired">
              Contratado
            </small>
            <StyledButton>
              <Button
                text={""}
                className={
                  item?.contratado
                    ? "btn-success-hired mx-1 btn-sm status p-0 radius-100"
                    : "btn-outline-success-hired mx-1 btn-sm status p-0 radius-100"
                }
                mainClass={"btn-over-accordion hired"}
                icon={{ prefix: "fas", iconName: "check" }}
                onClick={() => handleHired(item)}
                data_for="text-hired"
              />
            </StyledButton>
          </div>
        )}
        <div className="comments-counter">
          {item.commentsCounter !== 0 && (
              <span className="badge text-bg-danger rounded-circle mx-1">{item.commentsCounter}</span>
            )}
        </div>
        <Button
          text={""}
          className={"btn-outline-danger mx-1 btn-sm min-47"}
          mainClass={"btn-over-accordion"}
          icon={{ prefix: "fas", iconName: "trash" }}
          onClick={() => handleDelete()}
        />
      </h2>
      <div
        id={"collapseTarget" + item.id}
        className="accordion-collapse collapse"
      >
        <div className="accordion-body">
          {<FullProveedorDetails row={item.proveedor} />}
          <FullProviderComments offer={item.id} />
          <div className={"mt-2 display-flex justify-content-center"}>
            <Button
              className="btn-secondary"
              text={"Mostrar Delegaciones"}
              onClick={() => setShowDelegations(!showDelegations)}
            />
            { haveDelegations ? (
              <Button
                  text={""}
                  className="btn-success btn-success-delegations btn-delegations btn-sm status p-0 radius-100"
                  mainClass={"btn-over-delegations delegation"}
                  icon={{ prefix: "fas", iconName: "check" }}
                  data_for="text-hired"
                />
            ) : '' }
          </div>
          {showDelegations && (
            <FullProviderDelegations providerID={item?.proveedor?.id} />
          )}
        </div>
      </div>
      <DeleteModal
        show={show}
        body={`${t("estas_seguro_de_borrar")} ${t("proveedor")}`}
        doClose={handleClose}
        doConfirm={handleConfirm}
      />
    </div>
  );
};

export default ObrasProveedoresListItem;

export const StyledButton = styled.div`
  .btn-outline-success-hired {
    color: lightgray !important;
    border-color: lightgray !important;
    &:hover {
      color: #000 !important;
      background-color: lightgray !important;
      border-color: lightgray !important;
    }
    &:focus {
      outline: none !important;
      box-shadow: rgb(62, 141, 99) !important;
    }
    &:active {
      color: #000 !important;
      background-color: lightgray !important;
      border-color: lightgray !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
    &:disabled {
      color: lightgray !important;
      background-color: transparent !important;
      border-color: lightgray !important;
    }
  }

  .btn-success-hired {
    color: #000 !important;
    border-color: #96ca6e !important;
    background-color: #96ca6e !important;
    &:hover {
      color: #000 !important;
      background-color: #5b9e7a !important;
      border-color: #519873 !important;
    }
    &:focus {
      outline: none !important;
      box-shadow: rgb(53, 120, 84) !important;
    }
    &:active {
      color: #000;
      background-color: #65a482 !important;
      border-color: #519873 !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
    &:disabled {
      color: #000 !important;
      background-color: #96ca6e !important;
      border-color: #96ca6e !important;
    }
  }
`;

const AllContainer = styled.div`
  position: relative;
  border-radius: 0.375rem;
  margin: 0.5rem 0;
  color: #212529;
  background-color: #fff;
  border: 1px solid #dee2e6;
`;

const ContainerHeader = styled.h2`
  margin-bottom: 0;
  font-size: 2rem;
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
`;

const ButtonAcordion = styled.button`
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  border: 0;
  overflow-anchor: none;
`;

const ContainerWords = styled.span`
  font-size: 0.9rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;
