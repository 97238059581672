import React, { useState } from "react";
import { Button } from "../components";

/**
 *
 * @param item
 * @param {function} doSearch
 * callback that returns the search string from the input
 * uses: style-components, rebass, reflexbox, react-bootstrap-icons
 * @param doReset
 * @param placeholder
 */
const SearchBar = ({
  item,
  doSearch,
  doReset,
  placeholder = "Buscar  obra...",
}) => {
  const [search, setSearch] = useState("");
  React.useEffect(() => {
    if (item) {
      setSearch(item);
    }
  }, [item]);

  const handleSearch = (search) => {
    if (search) {
      doSearch(search);
    } else {
      setSearch("");
      doSearch();
    }
  };

  const handleReset = () => {
    doReset();
    setSearch("");
  };

  const handleSearchOnReturn = (e) => {
    if (e.key === "Enter") {
      doSearch(search);
    }
  };

  return (
    <div className={"search-component mb-3 mt-3"}>
      <div className={"input-search"}>
        <input
          className="form-control"
          type="text"
          placeholder={placeholder}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => handleSearchOnReturn(e)}
          name={"search"}
        />
      </div>
      <div className={"buttons-search"}>
        <Button
          text={"Limpiar"}
          className={"btn-secondary"}
          icon={{ prefix: "fas", iconName: "eraser" }}
          mx={"mx-2"}
          type="button"
          onClick={handleReset}
        />
        <Button
          text={"Buscar"}
          className={"btn-primary"}
          icon={{ prefix: "fas", iconName: "search" }}
          onClick={() => handleSearch(search)}
        />
      </div>
    </div>
  );
};

export default SearchBar;
