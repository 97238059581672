import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import ObrasEditForm from "./ObrasEditForm";

import { getStatusWork } from "../obras/status.work.service";
import { getUser } from "../../logic/logic";
import { MainTitle } from "../../common/StyledComponents";
import Loading from "../../common/Loading";

const ObrasEdit = ({ final }) => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { id } = params;
    const getData = async (id) => {
      setIsLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/obras/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        const data = await res.json();
        delete data.ofertas;
        delete data.users;
        setData(data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error(`${t("error")}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      getData(id);
    }
  }, []);

  /**
  * @author Rafael Duarte <elyouus94@gmail.com>
  * @date 30/05/2022
  * @description process the data to send to the server
  */

  const handleSaveDocumentation = async (data) => {

    const documentation = {
      epigraphs_work: data.epigrafes_para_ofertas,
      blueprints: data.planos,
      obra: data.id,
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/documentacion-de-la-obras`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "jdo-userToken"
          )}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(documentation),
      });
      if (res.status !== 200) {
        console.error("Bad request");
      }
      await res.json();
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  }

  const handleDeleteDocumentation = async (data) => {
    const id = data?.documentacion_obra?.id;

    if (!id) { return; };

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/documentacion-de-la-obras/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "jdo-userToken"
          )}`,
          "Content-Type": "application/json",
        },
      });
      if (res.status !== 200) {
        console.error("Bad request");
      }
      await res.json();
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  }

  const handleProcessData = async (data) => {
    /**
      * @author Rafael Duarte <elyouus94@gmail.com>
      * @date 30/05/2022
      * @description call of service to get the status of the work
      */
    const { id } = data;
    if (id) {
      // delete data.user;
      delete data.participantes;
      delete data.estado_obra;
      delete data.documentacion_obra;
      delete data.capitulos;
    }

    const status = await getStatusWork();

    let statusId = 0;

    const isObra = history.location.pathname.includes("obras");

    if (isObra) {
      const state = status.filter((element) => element.type === 'ejecutada')[0];
      statusId = state.id;
    } else {
      const state = status.filter((element) => element.type === 'en-curso')[0];
      statusId = state.id;
    }

    if(data?.fecha_final === undefined || data?.fecha_final === null) {
      const state = status.filter((element) => element.type === 'en-curso')[0];
      statusId = state.id;
    } else {
      const state = status.filter((element) => element.type === 'ejecutada')[0];
      statusId = state.id;
    }

    const obra = { ...data, estado_obra: statusId };
    return obra;
  }
  /** #@- */

  const handleSave = async (newData) => {

    let user = getUser();

    if (user) {
      const { id } = newData;
      newData.pem = Number(newData.pem);

      const dataEdit = await handleProcessData(newData);

      if (id) {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/obras/${id}`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "jdo-userToken"
                )}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(dataEdit),
            }
          );
          if (res.status !== 200) {
            console.error("Bad request");
          }
          // const data =
          const response = await res.json();

          await handleDeleteDocumentation( response );

          dataEdit.id = response.id;

          await handleSaveDocumentation( dataEdit );

          setTimeout(
            history.location.pathname.includes("obras")
              ? history.push("/obras")
              : history.push("/ejecucion"),
            500
          );
        } catch (err) {
          toast.error(`${t("error")}`);
        }
      } else {
        // dataEdit.user = user;

        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/obras`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify( dataEdit ),
          });
          if (res.status !== 200) {
            console.error("Bad request");
          }
          // const data =
          const response = await res.json();

          dataEdit.id = response.id;

          await handleSaveDocumentation( dataEdit );

          setTimeout(
            () =>
              // history.location.pathname.includes("obras")
              newData.final
                ? history.push("/obras")
                : history.push("/ejecucion"),
            500
          );
        } catch (err) {
          toast.error(`${t("error")}`);
        }
      }
    } else {
      toast.error(`${t("not_auth")}`);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  if (!isLoading) {
    return (
      <Container>
        <Row>
          <Col>
            <div className={"card p-3"}>
              <div className={"card-title"}>
                <div className={"d-flex justify-content-between"}>
                  <MainTitle>{data ? t("editar_obra") : t("nueva_obra")}</MainTitle>
                </div>
              </div>
              <div className={"card-body pt-0"}>
                <ObrasEditForm item={data} final={final} doSave={handleSave} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

    );
  }
};

export default ObrasEdit;
