import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Loading from '../common/Loading';
import useAppContext from "../data/store";
import ObrasProveedoresList from "../components/obras/ObrasProveedoresList";
import ObrasCapitulosList from "../components/obras/ObrasCapitulosList";
import ObrasOfertasListItem from "../components/obras/ObrasOfertasListItem";
import { Etiqueta, Nombre, Valor } from "../common/StyledComponents";

const ObrasOfertasAddProveedor = ({ isLogged, final }) => {
  const { t } = useTranslation();
  const [ {app }, dispatch] = useAppContext();
  const [datos, setDatos] = useState(null);
  const [obra, setObra] = useState(null);
  const params = useParams();
  const [allDataToUsersDuty, setAllDataToUsersDuty] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const { id } = params;
    setObra(id);

    if (id) {
      getData(id, 0, 9999);
    }

    dispatch({
      origin: "app",
      type: "SET_CAPITULO",
      capitulo: null,
    });
    dispatch({
      origin: "app",
      type: "SET_OFERTA",
      capitulo: null,
    });
  }, []);

  const getData = async (id, page, size) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/obras/${id}/detalles`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const data = await res.json();
      dispatch({
        origin: "app",
        type: "SET_OBRA",
        obra: data,
      });
      setDatos(data);
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  const handleDelete = async (item) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/ofertas/${item.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        console.error("Bad request");
      }
      const { id } = params;
      getData(id, 0, 9999);
    } catch (err) {
      console.error("err :>> ", err);
    }
  };

  const handleSetCategory = (category) => {
    setCategory(category)
  }

  // ************************** FUNCTIONS

  if (!datos) {
    return <Loading />;
  }
  if (datos) {
    return (
      <div className={"ofertas-proveedor"}>
        <div className="item-list">
          <div className={"row"}>
            <div className={"col col-12"}>
              <Nombre>{datos.titulo}</Nombre>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Valor>{datos.constructora || "-"}</Valor>
              <Etiqueta>{t("constructora")} </Etiqueta>
            </div>
            <div className="col">
              <Valor>{datos.comunidad || "-"}</Valor>
              <Etiqueta>{t("comunidad")}</Etiqueta>
            </div>
            <div className="col">
              <Valor>{datos.provincia || "-"}</Valor>
              <Etiqueta>{t("provincia")} </Etiqueta>
            </div>
          </div>
        </div>

        {!datos.fecha_final ? (
          <div className={"row"}>
            <div className={"col col-3"}>
              <ObrasCapitulosList
                obra={obra}
                allData={datos}
                setAllDataToUsersDuty={setAllDataToUsersDuty}
                handleSetCategory={handleSetCategory}
              />
            </div>
            <div className={"col col-9"}>
              <ObrasProveedoresList
                obra={obra}
                isOferta={true}
                allData={datos}
                allDataToUsersDuty={allDataToUsersDuty}
                category={category}
              />
            </div>
          </div>
        ) : (
          <div className={"row"}>
            <div className={"col"}>
              <ObrasOfertasListItem item={datos} doDelete={handleDelete} />
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default ObrasOfertasAddProveedor;
