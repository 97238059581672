import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";
import { Row, Col, Form, Button, ButtonToolbar } from "react-bootstrap";
import Error from "../../common/Error";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const initial = {
  id: null,
  nombre: null,
  email: null,
  password: null,
};

const validacion = Yup.object().shape({
  nombre: Yup.string().typeError("valor-texto").required("obligatorio"),
  email: Yup.string().email().typeError("valor-email").required("obligatorio"),
  password: Yup.string().typeError("valor-texto").when("id", {
        is: value => _.isNull(value),
        then: Yup.string().required("obligatorio")
      }),
});

const EmpleadosEditForm = ({item, doDelete}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState();

  const onSubmit = async (data, { setSubmitting }) => {
    setIsLoading(true);
    const newData = cleanForm(data);

    if (newData.id) {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${newData.id}`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "jdo-userToken"
                )}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newData),
            }
          );
          if (res.status !== 200) {
            console.error("Bad request");
          }
          const data = await res.json();
          history.push("/colaboradores")
        } catch (err) {
          toast.error(`${t("error")}`);
        }
      } else {
        // create
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          });
          if (res.status !== 201) {
            console.error("Bad request");
          }
          const data = await res.json();
          history.push("/colaboradores");
        } catch (err) {
          toast.error(`${t("error")}`);
        }    }
    setIsLoading(false);
  };

  const cleanForm = (data) => {
    const newData = { ...data };
    newData.username = data.email;
    newData.confirmed = true;
    newData.empleado = JSON.parse(localStorage.getItem("jdo-userData")).id

    return newData;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={item || initial}
        validationSchema={validacion}
        validateOnMount={false}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          touched,
          errors,
          isValid,
          isSubmiting,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* nombre */}
            <Row>
              <Col>
                <Form.Group controlId="nombre">
                  <Form.Label>{t("nombre")}*</Form.Label>
                  <Error touched={touched.nombre} message={errors.nombre} />
                  <Form.Control
                    name="nombre"
                    type="text"
                    value={values.nombre || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* email */}
            <Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>{t("email_personal")}*</Form.Label>
                  <Error touched={touched.email} message={errors.email} />
                  <Form.Control
                    name="email"
                    type="text"
                    value={values.email || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* password */}
            {!item && (
              <Row>
                <Col>
                  <Form.Group controlId="password">
                    <Form.Label>{t("password")}*</Form.Label>
                    <Error
                      touched={touched.password}
                      message={errors.password}
                    />
                    <Form.Control
                      name="password"
                      type="password"
                      value={values.password || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Barra />
            <Row>
              <Col>
                <ButtonToolbar style={{ justifyContent: "flex-end" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    size="sm"
                    block
                    disabled={isSubmiting}
                  >
                    Guardar
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

const Barra = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #8c8b8b;
`;

export default EmpleadosEditForm;
