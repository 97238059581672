import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Formik } from "formik";
import { Button } from "rebass/styled-components";
import { Box, Flex } from "reflexbox";
import { Container, Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { Label, Radio } from "@rebass/forms";

import Error from "../../common/Error";
import Leyenda from "../../common/Leyenda";

const initial = {
  c01: null,
  c02: null,
  c03: null,
  c04: null,
  c05: null,
  c06: null,
  c07: null,
  c08: null,
  c09: null,
  c10: null,
  c11: null,
  c12: null,
};

const OfertasValorarForm = ({ obra, participante, valoracion, doSave }) => {
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const history = useHistory();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/valoracions/${valoracion}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        const data = await res.json();
        setData(data);
      } catch (err) {
        toast.error(`${t("error")}`);
      }
    };
    if (valoracion) {
      getAll();
    }
  }, [valoracion]);

  const onSubmit = async (data, { setSubmitting }) => {
    const newData = cleanForm(data);
    // doSave(newData);
    const { id } = newData;
    if (id) {
      // update
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/valoracions/${id}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        setTimeout(
          () =>
            history.location.pathname.includes("obras")
              ? history.push(`/obras/detalles/${obra.id}`)
              : history.push(`/ejecucion/detalles/${obra.id}`),
          500
        );
        // setTimeout(() => history.push(`/ejecucion`), 500);
      } catch (err) {
        toast.error(`${t("error")}`);
      }
    } else {
      // create
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/valoracions`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        setTimeout(
          () =>
            history.location.pathname.includes("obras")
              ? history.push(`/obras/detalles/${obra.id}`)
              : history.push(`/ejecucion/detalles/${obra.id}`),
          500
        );
      } catch (err) {
        toast.error(`${t("error")}`);
      }
    }
  };

  const cleanForm = (data) => {
    const newData = { ...data };
    newData.participante = participante;
    delete newData.oferta_id;
    delete newData.created_at;
    delete newData.updated_at;
    // delete data.proveedor;
    return newData;
  };

  if (!isLoading) {
    return (
      <>
        {/* <div className="containers"> */}
        <Container>
          <Row>
            <Col>
              <Box my={2} fontSize={"11px"}>
                <b>Nota:</b> Las valoraciones son anónimas, no se visualiza por
                ningún usuario de la plataforma las calificaciones que obtiene
                una empresa por parte de un jefe de obra, sólo se ven en el
                listado de proveedores, que serán la media aritmética de todas
                las valoraciones que el resto de los usuarios asignen a cada
                empresa.
              </Box>
              <Formik
                enableReinitialize={true}
                initialValues={data || initial}
                onSubmit={onSubmit}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  isValid,
                  isSubmiting,
                  setFieldValue,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      {/* begin field */}
                      <MalNormalBien
                        concepto="Cumplimiento de plazo"
                        ejemplo="Colaboración en incremento de personal cuando se le ha requerido, trabajo en festivos,..."
                        name="c01"
                        value={values.c01}
                        setFieldValue={setFieldValue}
                      />
                      <MalNormalBien
                        concepto="Capacidad técnica"
                        ejemplo="Capacidad para crear planos específicos, informes, etc..."
                        name="c02"
                        value={values.c02}
                        setFieldValue={setFieldValue}
                        noaplica
                      />
                      <MalNormalBien
                        concepto="Colaboración"
                        ejemplo="Muestra interes en resolver problemas del jefe de obra,..."
                        name="c03"
                        value={values.c03}
                        setFieldValue={setFieldValue}
                      />
                      <MalNormalBien
                        concepto="Seguridad y salud"
                        ejemplo="Cumplimiento de las medidas de protección y mantenimiento de las medidas de proteccion colectivas,..."
                        name="c04"
                        value={values.c04}
                        setFieldValue={setFieldValue}
                      />
                      <MalNormalBien
                        concepto="Cumplimiento de los precios pactados"
                        ejemplo="Penaliza la aparición de demasiados precios contradictorios,..."
                        name="c05"
                        value={values.c05}
                        setFieldValue={setFieldValue}
                      />
                      <MalNormalBien
                        concepto="Calidad de los trabajos"
                        ejemplo="Resultado general de las unidades de obra ejecutadas,..."
                        name="c06"
                        value={values.c06}
                        setFieldValue={setFieldValue}
                      />
                      <MalNormalBien
                        concepto="Capacidad económica"
                        ejemplo="Pulmón financiero,..."
                        name="c07"
                        value={values.c07}
                        setFieldValue={setFieldValue}
                      />
                      {/* end field */}
                      <SiNo
                        concepto="Compromiso con el medio ambiente"
                        ejemplo="Ha aportado contenedores de residuos aparte de los de la constructora,... (MARCAR 'NO' NO PENALIZA, BONIFICA SI SE MARCA EL 'SÍ')"
                        name="c08"
                        value={values.c08}
                        setFieldValue={setFieldValue}
                        noaplica
                      />
                      <SiNo
                        concepto="Subcontratación"
                        ejemplo="Ha subcontratado a otras empresas,... (MARCAR 'NO' NO BONIFICA, MARCAR 'SI' SÍ PENALIZA.)"
                        name="c09"
                        value={values.c09}
                        setFieldValue={setFieldValue}
                      />
                      <SiNo
                        concepto="Problemas con la entrega de los boletines de obra"
                        ejemplo="Ha coaccionado con el pago de la última factura para la entrega de boletines,..."
                        name="c10"
                        value={values.c10}
                        setFieldValue={setFieldValue}
                        noaplica
                      />
                      <SiNo
                        concepto="Compromiso postventa"
                        ejemplo="Ha atendido a las reclamaciones por repasos tras finalizar la obra,..."
                        name="c11"
                        value={values.c11}
                        setFieldValue={setFieldValue}
                        noaplica
                      />

                      <SiNo
                        concepto="Volvería a trabajar con el"
                        ejemplo=" "
                        name="c12"
                        value={values.c12}
                        setFieldValue={setFieldValue}
                      />

                      <Barra />
                      <Flex justifyContent="flex-end">
                        <Box mr={2}>
                          <Button
                            variant="secondary"
                            onClick={() =>
                              history.location.pathname.includes("obras")
                                ? history.push(`/obras/detalles/${obra?.id}`)
                                : history.push(
                                    `/ejecucion/detalles/${obra?.id}`
                                  )
                            }
                          >
                            {t("cancelar")}
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            px={80}
                            variant="primary"
                            type="submit"
                            disabled={isLoading}
                          >
                            {t("Puntuar")}
                          </Button>
                        </Box>
                      </Flex>
                    </Form>
                  );
                }}
              </Formik>

              <Box my={2} fontSize={"11px"}>
                <Leyenda />
              </Box>
            </Col>
          </Row>
        </Container>
        {/* </div> */}
      </>
    );
  }
};

const Barra = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #8c8b8b;
`;

export default OfertasValorarForm;

const MalNormalBien = ({
  concepto,
  ejemplo,
  name,
  value,
  setFieldValue,
  noaplica,
}) => (
  <Flex
    justifyContent="space-between"
    p={1}
    sx={{ borderBottom: "1px solid #eee" }}
  >
    <Box flex="6">
      <Pregunta>{concepto}</Pregunta>
      <Ejemplo>{ejemplo}</Ejemplo>
    </Box>
    <Box flex="1">
      <Label className="d-flex flex-column align-items-center text-center">
        <Radio
          name={name}
          value="1"
          checked={value === 1}
          onChange={() => setFieldValue(name, 1)}
        />
        <Respuesta>Mal</Respuesta>
      </Label>
    </Box>
    <Box flex="1">
      <Label className="d-flex flex-column align-items-center text-center">
        <Radio
          name={name}
          value="2"
          checked={value === 2}
          onChange={() => setFieldValue(name, 2)}
        />
        <Respuesta>Normal</Respuesta>
      </Label>
    </Box>
    <Box flex="1">
      <Label className="d-flex flex-column align-items-center text-center">
        <Radio
          name={name}
          value="3"
          checked={value === 3}
          onChange={() => setFieldValue(name, 3)}
        />
        <Respuesta>Óptimo</Respuesta>
      </Label>
    </Box>
    {noaplica ? (
      <Box flex="1">
        <Label className="d-flex flex-column align-items-center text-center">
          <Radio
            name={name}
            value="4"
            checked={value === 4}
            onChange={() => setFieldValue(name, 4)}
          />
          <Respuesta>No Aplica</Respuesta>
        </Label>
      </Box>
    ) : (
      <Box flex="1">&nbsp;</Box>
    )}{" "}
  </Flex>
);

const SiNo = ({ concepto, ejemplo, name, value, setFieldValue, noaplica }) => (
  <Flex
    justifyContent="space-between"
    p={1}
    sx={{ borderBottom: "1px solid #eee" }}
  >
    <Box flex="6">
      <Pregunta>{concepto}</Pregunta>
      <Ejemplo>{ejemplo}</Ejemplo>
    </Box>
    <Box flex="1">
      <Label className="d-flex flex-column align-items-center text-center">
        <Radio
          name={name}
          value="2"
          checked={value === 2}
          onChange={() => setFieldValue(name, 2)}
        />
        <Respuesta>Sí</Respuesta>
      </Label>
    </Box>
    <Box flex="1">
      <Label className="d-flex flex-column align-items-center text-center">
        <Radio
          name={name}
          value="1"
          checked={value === 1}
          onChange={() => setFieldValue(name, 1)}
        />
        <Respuesta>No</Respuesta>
      </Label>
    </Box>
    {noaplica ? (
      <Box flex="1">
        <Label className="d-flex flex-column align-items-center text-center">
          <Radio
            name={name}
            value="3"
            checked={value === 3}
            onChange={() => setFieldValue(name, 3)}
          />
          <Respuesta>No Aplica</Respuesta>
        </Label>
      </Box>
    ) : (
      <Box flex="1">&nbsp;</Box>
    )}
    <Box flex="1">&nbsp;</Box>
  </Flex>
);

const Pregunta = styled.div`
  font-size: 14px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.primary};
`;
const Ejemplo = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.primary};
`;
const Respuesta = styled.div`
  font-size: 12px;
  font-weight: 400;
`;
