import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Col, Container, Row, Table } from "react-bootstrap";

import { toast } from "react-toastify";

import { useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import { MainTitle } from "../../common/StyledComponents";

import moment from "moment";
import NumberFormat from "react-number-format";

const ObrasEstudiosView = ({ final }) => {
  const { t, lang } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [jdo, setJdo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { id } = params;

    const jdo = JSON.parse(localStorage.getItem("jdo-userData"));
    setJdo(jdo.nombre);

    const getData = async (id) => {
      setIsLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/obras/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "jdo-userToken"
              )}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          console.error("Bad request");
        }
        const data = await res.json();
        delete data.ofertas;
        delete data.users;
        setData(data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error(`${t("error")}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      getData(id);
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (!isLoading) {
    return (
      // <div className="container">
      //   <Card variant="sombra" width="100%">
      <>
        <Container>
          <Row>
            <Col>
              <div className={"card p-3"}>
                <MainTitle>{t("detalles_obra_en_curso")}</MainTitle>
                <Table striped bordered hover size="sm">
                  {/* <thead>
                <tr>
                  <th>Nombre</th>
                  <th>CIF</th>
                </tr>
              </thead> */}
                  <tbody>
                    <tr>
                      <td>JEFE DE OBRA</td>
                      <td>{(data && data.user.nombre) || ""}</td>
                    </tr>
                    <tr>
                      <td>CODIGO DE OBRA</td>
                      <td>{(data && data.codigo) || ""}</td>
                    </tr>
                    <tr>
                      <td>TITULO</td>
                      <td>{(data && data.titulo) || ""}</td>
                    </tr>
                    <tr>
                      <td>PROPIEDAD</td>
                      <td>{(data && data.propiedad) || ""}</td>
                    </tr>
                    <tr>
                      <td>CONSTRUCTORA</td>
                      <td>{(data && data.constructora) || ""}</td>
                    </tr>
                    <tr>
                      <td>DIRECCIÓN FACULTATIVA</td>
                      <td>{(data && data.direccion_facultativa) || ""}</td>
                    </tr>
                    <tr>
                      <td>PEM</td>
                      <td>
                        {data && (
                          <NumberFormat
                            value={data.pem || ""}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"€"}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>PROVINCIA</td>
                      <td>{(data && data.provincia) || ""}</td>
                    </tr>
                    <tr>
                      <td>DIRECCIÓN</td>
                      <td>{(data && data.direccion) || ""}</td>
                    </tr>
                    <tr>
                      <td>FECHA INICIO</td>
                      <td>
                        {(data &&
                          data.fecha_inicio &&
                          moment(data.fecha_inicio).format("DD MMM YYYY")) ||
                          ""}
                      </td>
                    </tr>
                    <tr>
                      <td>FECHA LÍMITE DE PRESENTACIÓN DE OFERTAS</td>
                      <td>
                        {(data &&
                          data.fecha_prevista_fin &&
                          moment(data.fecha_prevista_fin).format(
                            "DD MMM YYYY"
                          )) ||
                          ""}
                      </td>
                    </tr>
                    <tr>
                      <td>CORREO ELECTRÓNICO DEL JEFE DE LA OBRA</td>
                      <td>
                        {(data &&
                          data?.user?.email_profesional &&
                          data?.user?.email_profesional) ||
                          ""}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <div className={"card p-3"}>
                <h5 className="altura" style={{ color: "#2c3684" }}>
                  Documentación de obra
                </h5>
                <Row className={"justify-content-start"}>
                  <Col xxl={"12"} xl={"12"} lg={"12"} md={"12"} sm={"12"} xs={"12"}>
                    {t("epigrafes_para_ofertas")}
                  </Col>
                </Row>
                <Row className={"justify-content-start"}>
                  <Col xxl={"12"} xl={"12"} lg={"12"} md={"12"} sm={"12"} xs={"12"}>
                    &nbsp;&nbsp;&nbsp;&nbsp;<a href={data?.documentacion_obra?.epigraphs_work}>{data?.documentacion_obra?.epigraphs_work}</a>
                  </Col>
                </Row>
                <Row className={"justify-content-start"}>
                  <Col xxl={"12"} xl={"12"} lg={"12"} md={"12"} sm={"12"} xs={"12"}>
                    {t("planos")}
                  </Col>
                </Row>
                <Row className={"justify-content-start"}>
                  <Col xxl={"12"} xl={"12"} lg={"12"} md={"12"} sm={"12"} xs={"12"}>
                    &nbsp;&nbsp;&nbsp;&nbsp;<a href={data?.documentacion_obra?.blueprints}>{data?.documentacion_obra?.blueprints}</a>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default ObrasEstudiosView;
